import React from 'react';
import './Jewellery.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Products from './Products';
import Material from './Material';
import Types from './Types';
import Colors from './Colors';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Jewellery() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className='CustomerOrderContainer brands-category-page'>
            <div className="title_text">
                <h1>Jewellery</h1>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Products" {...a11yProps(0)} />
                    <Tab label="Material" {...a11yProps(1)} />
                    <Tab label="Types" {...a11yProps(2)} />
                    <Tab label="Colors" {...a11yProps(3)} />
                </Tabs>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box >
                    <div className='productserchBtn pageFilterBar '>
                        <div className='searchbaar'>
                        </div>
                    </div>
                </Box>
                <div className='tab-content'>
                    <TabPanel value={value} index={0}>
                        <Products />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Material />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Types />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Colors />
                    </TabPanel>
                </div>
            </Box>

        </div>
    )
}

export default Jewellery