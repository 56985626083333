import React, { useEffect, useState } from 'react';
import './Header.css'
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PopupState from 'material-ui-popup-state';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Scrollbar } from 'react-scrollbars-custom';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useNavigate } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getNitification } from '../../Api';
import axios from "axios";
import { Link } from "react-router-dom";




const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    localStorage.removeItem('adminData')
    sessionStorage.removeItem('adminData')
    window.location.href = "/panel/login"
  }

  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate('/panel/changepassword')
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu style={{ top: '30px', paddingTop: '8px' }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleOpenSidebar = () => {
    document.getElementById("menu-toggler").click();
    return false;
  }

// Notification History

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }

  const [notification , setNotification] = useState({})

  const fetchNotification = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(getNitification)
      .then((res) => {
        res.data.result.docs.map((ele) => {
          ele.notificationImage = "https://" + ele.notificationImage
        })
        setNotification(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchNotification();
  }, [])

  // Notification Permission
  const [isPermission , setIsPermission] = useState(false)
  var data = {}
  if (localStorage.getItem('adminData')) data = JSON.parse(localStorage.getItem('adminData'))
  if (sessionStorage.getItem('adminData')) data = JSON.parse(sessionStorage.getItem('adminData'))

  useEffect(() => {
    if (data.permissionData) {
      if (data.type === "SUPER") {
        setIsPermission(true)
      } else if (data.permissionData.some(item => item.key === "NOTIFICATIONS")) {
        setIsPermission(true)
      } else {
        setIsPermission(false)
      }
    }   
  },[data])

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <div className="mobile-menu">
            <button className="menu-toggler" onClick={handleOpenSidebar} id='menu-toggler' type="button">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop} >
            <div className='header-btn'>
            {
              isPermission ? 
                <PopupState variant="popover" popupId="notifivation-popup">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton color="secondary" aria-label="add an alarm" {...bindTrigger(popupState)} className='dyFlextIcon'>
                        <NotificationsActiveIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <Scrollbar style={{ width: 500, height: 500 }}>
                        {notification.length > 0 ?
                        <div className='notification-list'>
                        {
                          Array.from(notification).map((item) => {
                            return (
                              <Link to={item.notificationType == 1 ? `/panel/vieworder/${item.notificationId}` : "/panel/forgot-password-requests"}>
                                <div className='item'>
                                  <div className='notification-image'>
                                    <img src={item.notificationImage} alt="" />
                                  </div>
                                  <div className='notification-body'>
                                    <h5>{item.notificationType == 1 ? 'New Order Arrived' : `New OTP Request Arrived From ${item.notificationTitle}`}</h5>
                                    <p>{item.notificationDescription}</p>
                                  </div>
                                </div>
                              </Link>
                            )
                          })
                        }
                      </div>  :
                      <div className=''>
                          <h4>No Notifications</h4>
                    </div>
                        } 
                        </Scrollbar>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState> : ''
              }
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}