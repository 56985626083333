const baseUrl = "https://dev.ahura.xyz:3002/api"
// const baseUrl = "https://dev.ahura.xyz:3002/api"
// http://dev.ahura.xyz:3002/api/promotion/user/
// const baseUrl = "http://192.168.1.10:3000/api"

export const getAdminLogin = `${baseUrl}/admin/adminLogin`
export const getCategory = `${baseUrl}/category`
export const getCategoryList = `${baseUrl}/category/getCategoryList`
export const getProduct = `${baseUrl}/product`
export const getProductUser = `${baseUrl}/product/user/productList`
export const getJewelleryProductUser = `${baseUrl}/jewelleryproduct/user/productList`
export const getJewelleryProductFilter = `${baseUrl}/jewelleryproduct/user/filterData`
export const getShoesProductFilter = `${baseUrl}/product/user/filterData`
export const getBrands = `${baseUrl}/brands`
export const getBrandsList = `${baseUrl}/brands/onlyList`
export const getColors = `${baseUrl}/color`
export const getColorsList = `${baseUrl}/color/getcolorlist`
export const getUserOrderList = `${baseUrl}/order/`
export const GetRecentOrderList = `${baseUrl}/order/`
export const GetCustomerList = `${baseUrl}/user`
export const GetEmployeeList = `${baseUrl}/employee/`
export const GetRoleList = `${baseUrl}/role/`
export const GetPromotionList = `${baseUrl}/promotion`
export const getPermission = `${baseUrl}/Permission/`
export const getCustomerOrder = `${baseUrl}/order/UserOrders/`
export const getDataCollection = `${baseUrl}/wishlist`
export const getChangePassword = `${baseUrl}/admin/change-password/`
export const getDashboardStats = `${baseUrl}/dashboard`
export const getForgotPasswordRequests = `${baseUrl}/forgotpass`
export const getCms = `${baseUrl}/cms`
export const getSliderCms = `${baseUrl}/cms/sliderCms`
export const getNitification = `${baseUrl}/notification/admin/notificationHistory`


export const getJewelleryProduct = `${baseUrl}/jewelleryproduct`
export const getJewelleryColor = `${baseUrl}/jewellerycolor`
export const getJewelleryColorList = `${baseUrl}/jewellerycolor/getJewelleryColorList`
export const getJewelleryType = `${baseUrl}/jewellerytype`
export const getJewelleryMaterial = `${baseUrl}/jewellerymaterial`