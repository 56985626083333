import React from 'react'

function PrivateRoute({ children }) {

    let token;
    if(localStorage.getItem("token") != null) {
        token = localStorage.getItem('token');
    } else if (sessionStorage.getItem('token') != null) {
        token = sessionStorage.getItem('token')
    }

    return token ? children : window.location.href = '/panel/login';
}

export default PrivateRoute