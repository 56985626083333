import React, { useState, useEffect } from "react";
import "./Customers.css";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { AiOutlinePlus } from "react-icons/ai";
import { FormControlLabel, IconButton } from "@material-ui/core";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import Typography from "@mui/material/Typography";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import PopupState from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import { bindTrigger, bindMenu } from "material-ui-popup-state";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography, MDBIcon,} from "mdb-react-ui-kit";
import Avtar from "../../Images/default-avatar.png";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import IosShareIcon from "@mui/icons-material/IosShare";
import Tooltip from "@mui/material/Tooltip";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { Link } from "react-router-dom";
import axios from "axios";
import { GetCustomerList } from "../../Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'
import PieChart from "./PieChart";
import AgePieChart from "./AgePieChart";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const secondmodel = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style = {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  width: "50%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: '1.5rem',
};
const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function Customers(props) {
  function MatDelete({ index }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteCustomer, setDeleteCustomer] = React.useState(false);
    const handleDelete = (e) => {
      handleOpen()
      let selected = rows.find((ele) => {
        return ele._id === index;
      })
      setDeleteCustomer(selected)
    }

    const handleDeleteCustomer = (e) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.delete(GetCustomerList + "/AdminuserDelete/" + deleteCustomer._id)
        .then((res) => {
          fetcCustomerList()
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })

      handleClose();
    }

    return (
      <FormControlLabel
        control={
          <>
            <div className="ActionButtons">
              <Tooltip title="Delete User">
                <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleDelete(e)} className="dyFlextIcon" >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="delete-data-model" sx={secondmodel}>
                  <Typography className="model-icon" id="modal-modal-title" variant="h6" component="h1" >
                    <DeleteSweepIcon />
                  </Typography>
                  <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                    <h2> Are You Sure! Want to Delete{" "} <strong>{deleteCustomer.userName}</strong> Record? </h2>
                    <p>Do you really want to delete these records? You can't view this in your list anymore if you delete! </p>
                  </Typography>
                  <Typography className="model-button">
                    <Button variant="contained" className="primaryBtn order_active" onClick={handleClose} >
                      No, Keep it
                    </Button>
                    <Button variant="contained" className="primaryBtn order_active green" onClick={(e) => handleDeleteCustomer(e)} >
                      Yes, Delete it
                    </Button>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </>
        }
      />
    );
  }

  const MatOrders = ({ index }) => {
    const handleCustomer = (e) => {

    }
    return (
      <FormControlLabel
        control={
          <>
            <Link to={`/panel/customerorder/${index}`}>
              <Tooltip title="Orders">
                <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleCustomer(e)} className="dyFlextIcon" >
                  <ManageHistoryIcon />
                </IconButton>
              </Tooltip>
            </Link>
          </>
        }
      />
    );
  };
  const columns = [
    { field: "srno", headerName: "ID", width: 130 },
    { field: "userName", headerName: "Name", width: 250 },
    { field: "createdAt", headerName: "Created", width: 250 },
    { field: "age", headerName: "Age", width: 250 },
    { field: "userMobileNo", headerName: "Mobile No", width: 200 },
    {
      field: "status",
      headerName: "Orders",
      width: 180,

      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
            <MatOrders index={prevents.row._id} />
          </div>
        );
      },
    },
    {
      field: "enbdesuser",
      headerName: "Disable User",
      width: 180,

      renderCell: (prevents) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatSwitchUser index={prevents.row.id} id={prevents.row._id} />
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (prevents) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatDelete index={prevents.row._id} />
          </div>
        );
      },
    },
  ];

  const MatSwitchUser = ({ index, id }) => {
    const handleDisableUser = (e) => {
      let selectUser = rows.find((ele) => {
        return ele._id === id;
      })

      if (selectUser.status == 1) {
        selectUser.status = 2;
      } else {
        selectUser.status = 1;
      }

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const disableUser = new FormData();
      disableUser.append('status', selectUser.status);
      axios.put(GetCustomerList + "/enableDisableUser/" + selectUser._id, disableUser)
        .then((res) => {
          fetcCustomerList();
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    }
    return (
      <FormControlLabel
        control={
          <>
            <Tooltip title="Disable User">
              <div>
                <input type="checkbox" hidden="hidden" onChange={(e) => handleDisableUser(e)} id={"user" + index} checked={rows[index - 1]?.status == 1 ? true : false} />
                <label className="switch" for={"user" + index}></label>
              </div>
            </Tooltip>
          </>
        }
      />
    );
  };

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [userData, setUserData] = useState({
    men: "",
    women: "",
    other: ""
  })
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    if (e.target.value != null) {
      setSearch(e.target.value)
    } else {
      setSearch("")
    }
    fetcCustomerList()
  }

  // Filter 

  const [filter , setFilter] = useState({
    minAge: "",
    maxAge: "",
    gender: ""
  })


  const [filterGender , setFilterGender] = useState([])
  const handleGenderFilter = (e) => {
    const { value } = e.target;
    if (filterGender.includes(Number(value))) {
      setFilterGender(filterGender.filter(item => item !== Number(value)));
    } else {
      setFilterGender([...filterGender, Number(value)]);
    }
  }

  const [minAge , setMinAge] = useState("")
  const [maxAge , setMaxAge] = useState("")
  const handleAgeFilter = (e , type) => {
    if(type == "min") {
      setMinAge(e.target.value)
    }
    if(type == "max") {
      setMaxAge(e.target.value)
    }
  }

  const applyFilter = () => {
    setFilter({
      ...filter,
      minAge : minAge,
      maxAge: maxAge,
      gender: filterGender
    })
  }
  
  const resetFilter = () => {
    setFilter({
      minAge: "",
      maxAge: "",
      gender: ""
    })
    setMinAge("")
    setMaxAge("")
    setFilterGender([])
  }

  // Get All Customers List
  const fetcCustomerList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${GetCustomerList}?page=${pageState.page}&limit=${pageState.pageSize}&search=${search}&gender=${filter.gender}&minAge=${filter.minAge}&maxAge=${filter.maxAge}`)
      .then((res) => {
        setUserData({
          ...userData,
          man: res.manCount,
          women: res.womenCount,
          other: res.otherCount
        })

        const json = res.data.result
        setPageState(old => ({ ...old, total: json.totalDocs }))

        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1;
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10

          let day = new Date(ele.createdAt).getDate();
          let month = new Date(ele.createdAt).getMonth() + 1;
          let year = new Date(ele.createdAt).getFullYear();
          ele.createdAt = `${day}/${month}/${year}`;

          if(ele.lastName === undefined) {
            ele.userName = ele.userName
          } else {
            ele.userName = ele.userName+" "+ele.lastName
          }

          if (ele?.status === 2) {
            ele.status = 2;
          } else {
            ele.status = 1;
          }
          if(ele.age === undefined) {
          ele.age = 0
        }
        });
        setRows(res.data.result.docs);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetcCustomerList();
  }, [pageState.page, pageState.pageSize, search, filter]);

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem("token");
  } else if (sessionStorage.getItem("token") != null) {
    token = sessionStorage.getItem("token");
  }

  const OpenExport = () => {
    document
      .querySelector(
        ".MuiDataGrid-toolbarContainer.css-1j9kmqg-MuiDataGrid-toolbarContainer button "
      )
      .click();
    return false;
  };

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="Products">
        <div className="product-wrapper">
          <h1>Customers</h1>
        </div>
        <div className="pie-Chart">
          <div className="chartItem">
            <h4>Gender Breakdown of Customers</h4>
            <PieChart />
          </div>
          <div className="chartItem">
            <h4>Age Distribution of Customers</h4>
            <AgePieChart />
          </div>
        </div>
        <div className="productserchBtn customer-page">
          <div className='fillter'>
            <PopupState variant="popover" popupId="demo-popup-menu customer-page">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" className='fillter_btn' {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                    Filter
                  </Button>
                  <Menu className="customer-page" {...bindMenu(popupState)}>
                    <div className="section_p_20 customer-page">
                      <div className="drwdownMenus">
                        <Button className='rst-Btn-Lnk' onClick={resetFilter} autoFocus  >
                          Reset
                        </Button>
                        <Button className='aply-Btn-Lnk' onClick={applyFilter} autoFocus>
                          Apply
                        </Button>
                      </div>
                    </div>
                    <div className='HL'></div>
                    <div className="filterData">
                      <div className="filter-item">
                        <label htmlFor="minAge">Age :</label>
                        <div className="innerFilter">
                          <input type="number" id="minAge" placeholder="min" value={minAge} onChange={(e) => handleAgeFilter(e , "min")} />  
                          to
                          <input type="number" id="maxAge" placeholder="max" value={maxAge} onChange={(e) => handleAgeFilter(e , "max")} />  
                        </div>
                      </div>
                      <div className="filter-item">
                        <label htmlFor="minAge">Gender :</label>
                        <div className="checkstatus">
                          <div className="allStetus">
                            <div className='status-item '>
                              <input type="checkbox" id='order-place' value={1} onChange={(e) => handleGenderFilter(e)} checked={filterGender.includes(1)} />
                              <label htmlFor="order-place">Male</label>
                            </div>
                            <div className='status-item '>
                              <input type="checkbox" id='shipped' value={2} onChange={(e) => handleGenderFilter(e)} checked={filterGender.includes(2)} />
                              <label htmlFor="shipped">Female</label>
                            </div>
                          </div>
                          <div className="allStetus ">
                            <div className='status-item'>
                              <input type="checkbox" id='delivered' value={3} onChange={(e) => handleGenderFilter(e)} checked={filterGender.includes(3)} />
                              <label htmlFor="delivered">Other</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </div>
          <div className="searchbaar">
            <div className="serach_icon">
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>
      <div style={{ height: 660, width: "100%" }}>
        <DataGrid
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="200" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="150" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            )
          }}
          rows={rows}
          columns={columns}
          rowCount={pageState.total}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPageState(old => ({ ...old, page: newPage + 1 }))
          }}
          onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
        // checkboxSelection
        />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default Customers;
