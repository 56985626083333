import React from 'react'
import Sidebar from '../Components/DefultSidebar/Sidebar'
import Header from '../Components/DefultHeader/Header'
import PrivateRoute from '../PrivateRoute/PrivateRoute'

function DefaultLayout({ page }) {
    return (
        <>
            <Sidebar>
                <Header />
                <div className='wrapper-main-cnt'>
                    <div className='body-layout'>
                        <PrivateRoute>
                            {page}
                        </PrivateRoute>
                    </div>
                </div>
            </Sidebar>
        </>
    )
}

export default DefaultLayout