import React from 'react'
import "./Home.css"
import Logo from '../../Images/NIX_logo.png'
import MokupImg from '../../Images/Nix_layers.png'
import PlayStore from '../../Images/PlayStore.png'
import AppStore from '../../Images/AppStore.png'
import Phone from '../../Images/Phone.png'
import Mail from '../../Images/Mail.png'
import Phone2 from '../../Images/call-dv.svg'
import Mail2 from '../../Images/mail-dv.png'
import Ahura from '../../Images/At-logo.png'
import { NavLink } from 'react-router-dom'

function Home() {
  return (
    <div className='homePage'>
      <div className='header'>
        <div className='page-container'>
          <NavLink to="/">
            <img src={Logo} className='logo' width={"180px"} alt="" />
          </NavLink>
          <ul>
            <li>
              <a href="tel:+251930252020"><img src={Phone2} alt="" />+251930252020</a>
            </li>
            <li>
              <a href="mailto:info@nix.et"><img src={Mail2} alt="" />Info@nix.et</a>
            </li>
          </ul>
        </div>
      </div>
      <div className='page-container'>
        <div className='home'>
          <div className='left'>
            <img src={Logo} className='logo' width={"180px"} alt="" />
            <h1>Change the way you shop. Fashion at your fingertips</h1>
            <p>Introducing NIX, the ultimate e-commerce app for shoe lovers in ETHIOPIA! With NIX, you can browse and shop for the latest shoe styles from top brands all in one place. Whether you're looking for sneakers, boots, heels, or sandals, NIX has got you covered.</p>
            <img className='mobile-only' src={MokupImg} alt="" />
            <div className='storeLinks'>
                <a href="https://play.google.com/store/apps/details?id=et.nix.nixstore"><img src={PlayStore} alt="" /></a>
                <a href="https://apps.apple.com/tr/app/nix-ethiopia/id6446590498"><img src={AppStore} alt="" /></a>
            </div>
          </div>
          <div className='right mokup'>
            <img src={MokupImg} alt="" />
          </div>
        </div>
        <div className='contact-details'>
          <div className="page-container">
            <ul>
              <li>
                <a href="tel:+251930252020"><img src={Phone} alt="" />+251930252020</a>
              </li>
              <li>
                <a href="mailto:info@nix.et"><img src={Mail} alt="" />Info@nix.et</a>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              </li>
            </ul>
            {/* <ul>
              <li>
                <a href="https://ahuratechnosoft.com">Designed and Developed by <span><img src={Ahura} /></span></a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home