import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PopupState from 'material-ui-popup-state';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { FormControlLabel, IconButton } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai'
import Menu from '@mui/material/Menu';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { getCategory, getColors } from "../../Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'


// hello
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Colors() {

  const [toggleEdit, setToggeEdit] = useState(true);
  const [data, setData] = useState({
    colorName: "",
  })

  const [newColor, setnewColor] = useState({});

  const MatEdit = ({ index }) => {
    const handleEditClick = () => {
      document.getElementById("edit-data").click();
      let newColor = rows.find((ele) => {
        return ele._id === index;
      })
      setToggeEdit(false)
      setData({
        colorName: newColor.colorName
      })
      setnewColor(newColor)
      console.log(index, newColor._id)
    }
    return <FormControlLabel
      control={
        <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
          <ModeEditOutlineOutlinedIcon />
        </IconButton>
      }
    />
  };

  // Delete Category
  function MatDelete({ index }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deleteColor, setDeleteColor] = useState([])
    const handleDeleteClick = (e) => {
      handleOpen()
      let selected = rows.find((ele) => {
        return ele._id === index;
      })
      setDeleteColor(selected)
      console.log(selected)
    };

    const handledeleteColor = (e) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.delete(getColors + "/" + deleteColor._id)
        .then((res) => {
          fetchColor()
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          console.log(err)
          toast.error(err.response.data.message, toastStyle);
        })
      handleClose();
    }

    return (
      <FormControlLabel
        control={
          <>
            <div className="ActionButtons">
              <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleDeleteClick(e)} className="dyFlextIcon" >
                <DeleteOutlinedIcon />
              </IconButton>
              <div className="delete-popup">
                {/* <Button onClick={handleOpen}></Button> */}
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                  <Box className="delete-data-model" sx={style}>
                    <Typography className="model-icon" id="modal-modal-title" variant="h6" component="h1" >
                      <DeleteSweepIcon />
                    </Typography>
                    <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                      <h2> Are You Sure! Want to Delete{" "} <strong>{deleteColor.colorName} </strong> Record? </h2>
                      <p>Do you really want to delete these records? You can't view this in your list anymore if you delete!</p>
                    </Typography>
                    <Typography className="model-button">
                      <Button variant="contained" className="primaryBtn order_active" onClick={handleClose} >
                        No, Keep it
                      </Button>
                      <Button variant="contained" className="primaryBtn order_active green" onClick={(e) => handledeleteColor(e)} >
                        Yes, Delete it
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </>
        }
      />
    );
  }

  const columns = [
    { field: 'srno', headerName: 'Color ID', width: 150 },
    { field: 'colorName', headerName: 'Color Name', width: 180 },
    { field: 'colorProductCount', headerName: 'Products', width: 150 },
    {
      field: "actions", headerName: "Actions", sortable: false, width: 200,
      renderCell: (prevent) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatEdit index={prevent.row._id} />
            <MatDelete index={prevent.row._id} />
          </div>
        );
      }
    }
  ];


  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }
  // Get All Category API Integration
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState({
    total: 0,
    page: 1,
    pageSize: 10
  })

  //Search
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    if (e.target.value == null) {
      setSearch("")
    } else {
      setSearch(e.target.value)
    }
  }


  const fetchColor = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getColors}?page=${pageState.page}&limit=${pageState.pageSize}&search=${search}`)
      .then((res) => {
        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
        })
        const json = res.data.result
        setPageState(old => ({ ...old, total: json.totalDocs }))
        setRows(res.data.result.docs)
        setIsLoading(false);
      })
  }

  useEffect(() => {
    fetchColor();
  }, [pageState.page, pageState.pageSize, search])

  // Add Color API Integration
  const handleChange = (e) => {
    setData({
      colorName: e.target.value,
    })
  }

  const addColor = (e) => {
    e.preventDefault()
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.post(getColors, data)
      .then((res) => {
        fetchColor()
        toast.success(res.data.message, toastStyle);
      }).catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
    setToggeEdit(true)
  }


  // Edit Category API

  const editColor = (e) => {
    e.preventDefault()
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(getColors + "/" + newColor._id, data)
      .then((res) => {
        fetchColor()
        toast.success(res.data.message, toastStyle);
      }).catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }


  return (
    <>
      <div className='innerpage-top brand_ceteg_design'>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button variant="contained" className='fillter_btn'  {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                Add Color
              </Button>
              <Menu className="data-dropdown" {...bindMenu(popupState)}>
                <div className="pad-20">
                  <div className="drwdownMenus">
                    <Form className="category-form">
                      <Form.Group className="form-control" controlId="formBasicEmail">
                        <Form.Control type="text" name="colorName" placeholder="Color Name" onChange={(e) => handleChange(e)} />
                      </Form.Group>
                      <Button className='aply-Btn-Lnk button-full' autoFocus onClick={(e) => addColor(e)}> Add Color </Button>
                    </Form>
                  </div>
                </div>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button variant="contained" className='edit-button fillter_btn' id="edit-data" {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                Edit Category
              </Button>
              <Menu className="data-dropdown" {...bindMenu(popupState)}>
                <div className="pad-20">
                  <div className="drwdownMenus">
                    <Form className="category-form edit ">
                      <Form.Group className="form-control" controlId="formBasicEmail">
                        <Form.Control type="text" name="colorName" value={data.colorName} onChange={(e) => handleChange(e)} placeholder="Color Name" />
                      </Form.Group>
                      <Button className='aply-Btn-Lnk button-full' autoFocus onClick={(e) => editColor(e)}> Edit Color </Button>
                    </Form>
                  </div>
                </div>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
        <div className='searchbaar'>
          <div className='serach_icon'>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div className='searchbaar brandCatSearch'>
        <div className='serach_icon'>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => handleSearch(e)}
        />
      </div>
      <div className="data-col data-table">
        <div className="brand-category-page" style={{ height: 660, width: '100%' }}>
          <DataGrid
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <img width="200" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <img width="150" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
                </Stack>
              )
            }}
            rows={rows}
            columns={columns}
            rowCount={pageState.total}
            pagination
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState(old => ({ ...old, page: newPage + 1 }))
            }}
            onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
          // checkboxSelection
          />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Colors