import React, { useState } from 'react';
import './login.css';
import Banner_image from "../../Images/banner.png";
import Bg_texture from "../../Images/bg-texture.jpg";
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import { Crypt, RSA } from 'hybrid-crypto-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAdminLogin } from '../../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from '../../firebase';


const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Login() {

    const [fcmToken, setFcmToken] = useState("")

    React.useEffect(() => {
        const msg = firebase.messaging();
        msg.requestPermission().then(() => {
            return msg.getToken();
        }).then((data) => {
            setFcmToken(data)
        })
    })

    var crypt = new Crypt();

    const [error, setError] = useState([]);
    const [error2, setError2] = useState([]);
    const [stayLoggedIn, setStayLoggedIn] = useState(false);

    const [state, setState] = useState({
        Email: "",
        Password: "",
        stayLoggedIn: ""
    });
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleChecked = (e) => {
        if (e.target.checked == true) {
            setStayLoggedIn(true)
        } else {
            setStayLoggedIn(false)
        }
    }

    const routes = [
        {
            path: "/panel/dashboard",
            key: "DASHBOARD"
        },
        {
            path: "/panel/order",
            key: "ORDERS"
        },
        {
            path: "/panel/products",
            key: "PRODUCTS"
        },
        {
            path: "/panel/customers",
            key: "USERS"
        },
        {
            path: "/panel/promotions",
            key: "PROMOTIONS"
        },

        {
            path: "/panel/datacollection",
            key: "DATACOLLECTION"
        },
        {
            path: "/panel/brands",
            key: "BRANDSCATEGORY"
        },
        {
            path: "/panel/roles",
            key: "ROLES"
        },
        {
            path: "/panel/employees",
            key: "EMPLOYEES"
        },
        {
            path: "/panel/forgot-password-requests",
            key: "FORGOTPASSREQUESTS"
        },
        {
            path: "/panel/cms",
            key: "CMS"
        }
    ]

    const handleLogin = (e) => {
        e.preventDefault()
        let encryptedPassword = crypt.encrypt(process.env.REACT_APP_KEY, state.password);

        const loginData = new FormData();
        loginData.append('email', state.email);
        loginData.append('password', encryptedPassword);
        loginData.append('fcmToken', fcmToken);

        axios.post(getAdminLogin, loginData)
            .then((res) => {
                const adminObj = {
                    id: res.data.result?._id,
                    permissionData: res.data.result?.permissionData ? res.data.result?.permissionData : [],
                    type: res.data.result?.type
                };
                if (stayLoggedIn) {
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem('adminData', JSON.stringify(adminObj))
                } else {
                    sessionStorage.setItem("token", res.data.token)
                    sessionStorage.setItem('adminData', JSON.stringify(adminObj))
                }
                if (adminObj) {
                    const flag = adminObj?.permissionData.filter((data) => data.key === "DASHBOARD");
                    if (flag.length > 0 || res.data.result?.type === "SUPER") {
                        window.location.href = "/panel";
                    } else {
                        const firstPath = routes.filter((data) => data.key === res.data.result?.permissionData[0].key);
                        window.location.href = firstPath[0].path
                    }
                }

                toast.success(res.message, toastStyle);
            })
            .catch((err) => {
                toast.error(err.response?.data.message, toastStyle);
            })
    }


    return (
        <>
            <div className='loginSection'>
                <div className='background'>
                    <img src={Bg_texture} alt="" className='banner-image' />
                </div>
                <div className='login-box'>
                    <div className='row'>
                        <div className='col-6'>
                            <img src={Banner_image} alt="" className='banner-image' />
                        </div>
                        <div className='col-6'>
                            <div className='login-block'>
                                <div className='heading'>
                                    <h2>Login</h2>
                                    <div className='login-form'>
                                        <Form>
                                            <Form.Group className="form-control" controlId="formBasicEmail">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
                                            </Form.Group>
                                            <Form.Group className="form-control" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name="password" onChange={(e) => handleChange(e)} />
                                            </Form.Group>
                                            <Form.Group className="checkbox" controlId="formBasicCheckbox">
                                                <div className='check'>
                                                    <Form.Check type="checkbox" checked={stayLoggedIn} onChange={(e) => handleChecked(e)} name="stayLoggedIn" label="Stay Signed in" />
                                                </div>
                                            </Form.Group>
                                            <Button variant="contained" type="submit" onClick={(e) => handleLogin(e)} className='primaryBtn order_export'>
                                                Login
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />        </>
    )
}

export default Login