import React, { useState, useEffect } from 'react'
import "./Cms.css"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import { getCms, getProduct, getSliderCms } from '../../Api';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DefaultProduct from '../../Images/default-product.png'
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Cms() {

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [aboutData, setAboutData] = useState({
    id: "",
    title: "",
    description: "",
  })
  const [aboutEditor , setAboutEditor] = useState("")
  const [contactData, setContactData] = useState({
    id: "",
    title: "",
    description: "",
  })
  const [contactEditor , setContactEditor] = useState("")
  const [helpData, setHelpData] = useState({
    id: "",
    title: "",
    description: "",
  })
  const [helpEditor , setHelpEditor] = useState("")
  const [slide1Data, setSlide1Data] = useState({
    id: "",
    image: "",
    type: 4,
  })
  const [slide1Image, setSlide1Image] = useState("")
  const [slide2Data, setSlide2Data] = useState({
    id: "",
    image: "",
    type: 4,
  })
  const [slide2Image, setSlide2Image] = useState("")
  const [slide3Data, setSlide3Data] = useState({
    id: "",
    image: "",
    type: 4,
  })
  const [slide3Image, setSlide3Image] = useState("")

  // Get All CMS List
  const fetchCMS = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(getCms)
      .then((res) => {
        console.log(res.data.resultData);
        let about = res.data.resultData.find((ele) => {
          return ele.type === 1;
        });
        setAboutData({
          ...aboutData,
          "id": about._id,
          "title": about.title,
          "description": about.description,
        })
        setAboutEditor(about.description)

        let contact = res.data.resultData.find((ele) => {
          return ele.type === 2;
        });
        console.log(contact)
        setContactData({
          ...contactData,
          id: contact._id,
          title: contact.title,
          description: contact.description,
        })
        setContactEditor(contact.description)

        let help = res.data.resultData.find((ele) => {
          return ele.type === 3;
        });
        setHelpData({
          ...helpData,
          "id": help._id,
          "title": help.title,
          "description": help.description,
        })
        setHelpEditor(help.description)

        let slider1 = res.data.resultData.find((ele) => {
          return ele.slider === 1;
        });
        setSlide1Image(slider1.image)
        setSlide1Data({
          ...slide1Data,
          "id": slider1._id,
        })

        let slider2 = res.data.resultData.find((ele) => {
          return ele.slider === 2;
        });
        setSlide2Image(slider2.image)
        setSlide2Data({
          ...slide2Data,
          "id": slider2._id,
        })

        let slider3 = res.data.resultData.find((ele) => {
          return ele.slider === 3;
        });
        setSlide3Image(slider3.image)
        setSlide3Data({
          ...slide3Data,
          "id": slider3._id,
        })
      })
  }
  useEffect(() => {
    fetchCMS();
  }, [])

  console.log(aboutEditor)
  // About Data Update
  const handleAboutData = (e) => {
    e.preventDefault();

    const editedAbout = {
      id: aboutData.id,
      title: aboutData.title,
      description: aboutEditor,
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getCms}/${aboutData.id}`, editedAbout)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Contact Data Update
  const handleContactData = (e) => {
    e.preventDefault();
    
    const editedContact = {
      id: contactData.id,
      title: contactData.title,
      description: contactEditor,
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getCms}/${contactData.id}`, editedContact)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Help Data Update
  const handleHelpData = (e) => {
    e.preventDefault();

    const editedHelp = {
      id: helpData.id,
      title: helpData.title,
      description: helpEditor,
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getCms}/${helpData.id}`, editedHelp)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Get Slider 1 Image
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = (e, data) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      if (data == "add") {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const productImageChange = new FormData();
        productImageChange.append('Image', file);
        axios.post(getProduct + "/productImage", productImageChange)
          .then((res) => {
            setSlide1Data({
              ...slide1Data,
              image: res.data.urlArray[0]
            })
          }).catch((err) => {
            toast.error(err.response.data.message, toastStyle);
          })
      }
    }
  };

  // Slider 1 Data Update
  const handleSlide1Data = (e) => {
    e.preventDefault();
    console.log(slide1Data)
    
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getSliderCms}/${slide1Data.id}`, slide1Data)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Get Slider 2 Image
  const uploadedImage2 = React.useRef(null);
  const imageUploader2 = React.useRef(null);

  const handleImageUpload2 = (e, data) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage2;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      if (data == "add") {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const productImageChange = new FormData();
        productImageChange.append('Image', file);
        axios.post(getProduct + "/productImage", productImageChange)
          .then((res) => {
            setSlide2Data({
              ...slide2Data,
              image: res.data.urlArray[0]
            })
          }).catch((err) => {
            toast.error(err.response.data.message, toastStyle);
          })
      }
    }
  };

  // Slider 2 Data Update
    const handleSlide2Data = (e) => {
    e.preventDefault();
    console.log(slide2Data)

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getSliderCms}/${slide2Data.id}`, slide2Data)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Get Slider 3 Image
  const uploadedImage3 = React.useRef(null);
  const imageUploader3 = React.useRef(null);

  const handleImageUpload3 = (e, data) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage3;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      if (data == "add") {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const productImageChange = new FormData();
        productImageChange.append('Image', file);
        axios.post(getProduct + "/productImage", productImageChange)
          .then((res) => {
            setSlide3Data({
              ...slide3Data,
              image: res.data.urlArray[0]
            })
          }).catch((err) => {
            toast.error(err.response.data.message, toastStyle);
          })
      }
    }
  };

  // Slider 3 Data Update
  const handleSlide3Data = (e) => {
    e.preventDefault();
    console.log(slide3Data)

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(`${getSliderCms}/${slide3Data.id}`, slide3Data)
      .then((res) => {
        fetchCMS()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message, toastStyle);
      })
  }


  return (
    <div className='brands-category-page cmsPage'>

      <div className="title_text">
        <h1>CMS Pages</h1>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box >
          <div className='productserchBtn pageFilterBar '>
            <Tabs value={value} onChange={handleChange} className="cmsTabs" aria-label="basic tabs example">
              <Tab label="About US" {...a11yProps(0)} />
              <Tab label="Contact Us" {...a11yProps(1)} />
              <Tab label="Need Help / FAQs" {...a11yProps(2)} />
              <Tab label="Intro Slider" {...a11yProps(3)} />
            </Tabs>
            <div className='searchbaar'>
            </div>
          </div>
        </Box>
        <div className='tab-content'>
          <TabPanel value={value} index={0}>
            <div className='cmspage-content'>
              <h2>About Us</h2>
              
              <div className='editData'>
                <Form>
                  <ReactQuill theme="snow" value={aboutEditor} onChange={setAboutEditor} />
                  <Button variant="contained" type="submit" onClick={(e) => handleAboutData(e)} className='primaryBtn order_export'>
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className='cmspage-content'>
              <h2>Contact Us</h2>
              <div className='editData'>
                <Form>
                  <ReactQuill theme="snow" value={contactEditor} onChange={setContactEditor} />
                  <Button variant="contained" type="submit" onClick={(e) => handleContactData(e)} className='primaryBtn order_export'>
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <div className='cmspage-content'>
              <h2>Need Help / FAQs</h2>
              <div className='editData'>
                <Form>
                  <ReactQuill theme="snow" value={helpEditor} onChange={setHelpEditor} />
                  <Button variant="contained" type="submit" onClick={(e) => handleHelpData(e)} className='primaryBtn order_export'>
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <div className='cmspage-content'>
              <h2>Intro Slider</h2>
              <div className='editData'>
                <Form>
                  <div className='sliderData'>
                    <div className='slide-item'>
                      <h4>Slide 1 Image</h4>
                      <div className='ChangePOrfileCard'   >
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "add")} ref={imageUploader} style={{ display: "none" }} />
                        <div className='UserProfileCard' onClick={() => imageUploader.current.click()} >
                          <img ref={uploadedImage} className='UserProfileImg' src={`https://${slide1Image}`} name='productImage' />
                          <p className='ProfileUploadImgTitle'><ImageSearchOutlinedIcon /><span> Upload Image</span></p>
                        </div>
                      </div>  
                      <Button variant="contained" type="submit" onClick={(e) => handleSlide1Data(e)} className='primaryBtn order_export'>
                        Update
                      </Button> 
                    </div>
                    <div className='slide-item'>
                      <h4>Slide 2 Image</h4>
                      <div className='ChangePOrfileCard'   >
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload2(e, "add")} ref={imageUploader2} style={{ display: "none" }} />
                        <div className='UserProfileCard' onClick={() => imageUploader2.current.click()} >
                          <img ref={uploadedImage2} className='UserProfileImg' src={`https://${slide2Image}`} name='productImage' />
                          <p className='ProfileUploadImgTitle'><ImageSearchOutlinedIcon /><span> Upload Image</span></p>
                        </div>
                      </div> 
                      <Button variant="contained" type="submit" onClick={(e) => handleSlide2Data(e)} className='primaryBtn order_export'>
                        Update
                      </Button> 
                    </div>
                    <div className='slide-item'>
                      <h4>Slide 3 Image</h4>
                      <div className='ChangePOrfileCard'   >
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload3(e, "add")} ref={imageUploader3} style={{ display: "none" }} />
                        <div className='UserProfileCard' onClick={() => imageUploader3.current.click()} >
                          <img ref={uploadedImage3} className='UserProfileImg' src={`https://${slide3Image}`} name='productImage' />
                          <p className='ProfileUploadImgTitle'><ImageSearchOutlinedIcon /><span> Upload Image</span></p>
                        </div>
                      </div> 
                      <Button variant="contained" type="submit" onClick={(e) => handleSlide3Data(e)} className='primaryBtn order_export'>
                        Update
                      </Button> 
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </TabPanel>
        </div>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Cms