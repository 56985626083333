import React, { useState, useEffect } from 'react';
import './Promotions.css'
import './AddPromo.css'
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PopupState from 'material-ui-popup-state';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import { FormControlLabel, IconButton } from '@material-ui/core';
import Avtar from '../../Images//images.png'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AiOutlinePlus } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Form from 'react-bootstrap/Form';
// import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import { getBrands, getCategory, GetCustomerList, getCustomerOrder, getJewelleryProductFilter, getJewelleryProductUser, getProduct, getProductUser, getShoesProductFilter } from '../../Api';
import { GetPromotionList } from "../../Api";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { Scrollbar } from 'react-scrollbars-custom';
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'
import { GiRunningShoe, GiJewelCrown } from 'react-icons/gi'
import { addDays, startOfDay } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const secondmodel = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  width: '50%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: '1.5rem',
};
const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Promotions() {
  const [isLoading, setIsLoading] = useState(true);

  // Get Shoes product Filter Data
  const [shoesFilter, setShoesFilter] = useState()
  const fetchShoesFilter = () => {
    axios.get(`${getShoesProductFilter}`)
      .then((res) => {
        setShoesFilter(res.data.filterElements)
      })
  }
  useEffect(() => {
    fetchShoesFilter()
  }, [])

  const [filterPro, setFilterPro] = useState({
    productOnsale: "",
    productBrand: "",
    productGender: "",
    productCategory: "",
    orderBy: "",
  })

  const [filterOnSale, setFilterOnSale] = useState("")
  const handleOnSaleFilter = (e) => {
    let temp;
    if (e.target.checked) {
      temp = 2;
    } else {
      temp = "";
    }
    setFilterOnSale(temp)
  }

  const [filterOrderBy, setFilterOrderBy] = useState("")
  const handleOrderBy = (e) => {
    setFilterOrderBy(e.target.value)
  }

  const [productType, setProductType] = useState("1")
  const handleProductType = (e) => {
    setSelectedProduct({
      ...selectedProduct,
      products: []
    })
    setProductType(e.target.value)
    if (e.target.value == 1) {
      setIsAllProducts(false)
    }
    handleResetFilter()
  }

  const [filterGender, setFilterGender] = useState([])
  const handleGenderFilter = (e) => {
    const { value } = e.target;
    if (filterGender.includes(Number(value))) {
      setFilterGender(filterGender.filter(item => item !== Number(value)));
    } else {
      setFilterGender([...filterGender, Number(value)]);
    }
  }

  const [filterBrand, setFilterBrand] = useState([])
  const handleBrandFilter = (e) => {
    const { value } = e.target;
    if (filterBrand.includes(value)) {
      setFilterBrand(filterBrand.filter(item => item !== value));
    } else {
      setFilterBrand([...filterBrand, value]);
    }
  }

  const [filterCategory, setFilterCategory] = useState([])
  const handleCategoryFilter = (e) => {
    const { value } = e.target;
    if (filterCategory.includes(value)) {
      setFilterCategory(filterCategory.filter(item => item !== value));
    } else {
      setFilterCategory([...filterCategory, value]);
    }
  }

  const [filterType, setFilterType] = useState([])
  const handleTypeFilter = (e) => {
    const { value } = e.target;
    if (filterType.includes(value)) {
      setFilterType(filterType.filter(item => item !== value));
    } else {
      setFilterType([...filterType, value]);
    }
  }

  const [filterMaterial, setFilterMaterial] = useState([])
  const handleMaterialFilter = (e) => {
    const { value } = e.target;
    if (filterMaterial.includes(value)) {
      setFilterMaterial(filterMaterial.filter(item => item !== value));
    } else {
      setFilterMaterial([...filterMaterial, value]);
    }
  }

  const [filterColor, setFilterColor] = useState([])
  const handleColorFilter = (e) => {
    const { value } = e.target;
    if (filterColor.includes(value)) {
      setFilterColor(filterColor.filter(item => item !== value));
    } else {
      setFilterColor([...filterColor, value]);
    }
  }

  const handleFilter = (e) => {
    e.preventDefault();
    setFilterPro({
      ...filterPro,
      productOnsale: filterOnSale,
      productGender: filterGender,
      orderBy: filterOrderBy,
      productBrand: filterBrand,
      productCategory: filterCategory,
      productType: filterType,
      productMaterial: filterMaterial,
      productColor: filterColor
    })
  }
  const handleResetFilter = (e) => {
    setFilterPro({
      productOnsale: "",
      productBrand: "",
      productGender: "",
      productCategory: "",
      orderBy: "",
      productType: "",
      productMaterial: "",
      productColor: ""
    })
    setFilterGender("");
    setFilterOnSale("");
    setFilterOrderBy("");
    setFilterBrand("");
    setFilterCategory("");
    setFilterType('');
    setFilterMaterial('');
    setFilterColor('');
  }



  const [selectedRows, setSelectedRows] = React.useState([]);
  console.log(selectedRows)
  const [promoImage, setpromofeaturedImage] = useState({})
  const [rowss, setRowss] = useState([]);

  const [addPromotion, setAddPromotion] = useState({
    promoImage: "",
    promotitle: "",
    selected_products: "",
    notificationBody: ""
  });
  const handleChange = (e) => {
    setAddPromotion({
      ...addPromotion,
      [e.target.name]: e.target.value,
    })
  }

  // edit promotion
  const [editPromo, setEditPromo] = useState({
    promoImage: "",
    promotitle: "",
    selected_products: "",
    notificationBody: ""
  })
  const handleEditPromo = (e) => {
    e.preventDefault()
    const newEditPromo = {
      ...editPromo,
      isJewelleryPromoForAllProduct: isAllProducts,
      promoType: productType
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.put(GetPromotionList + "/" + newEditPromo._id, newEditPromo)
      .then((res) => {
        fetcPromotionList()
        toast.success(res.data.message, toastStyle);
        handleEditClose()
      }).catch((err) => {

      })
  }
  function MatEdit({ index }) {
    const handleEditClick = (e) => {
      handleEditOpen()
      let selectPromo = rows.find((ele) => {
        return ele._id === index;
      })
      setEditPromo(selectPromo)
      setEditProducts(selectPromo.selected_products)
      setProductType(selectPromo?.promoType)
      setIsAllProducts(selectPromo?.isJewelleryPromoForAllProduct)
    };

    return <FormControlLabel
      control={<>
        <div className='ActionButtons'>
          <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} className='dyFlextIcon'>
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        </div>
      </>} />;
  }

  // Add Promotion API
  const handleAddPromotion = (e) => {
    e.preventDefault()
    let slectedProduct = [];
    Array.from(selectedRows)?.map((ele) => {
      slectedProduct.push(ele._id)
    })
    let newPromotion = {
      ...addPromotion,
      selected_products: selectedProduct.products,
      promoImage: promoImage,
      isJewelleryPromoForAllProduct: isAllProducts,
      promoType: productType
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.post(GetPromotionList, newPromotion)
      .then((res) => {
        fetcPromotionList()
        toast.success(res.data.message, toastStyle);
        handleClose()
        newPromotion = "";
      }).catch((err) => {

        toast.error(err.response.data.message, toastStyle);
        newPromotion = "";
      })
  }


  const [productPageState, setproductPageState] = useState({
    total: 0,
    page: 0,
    pageSize: 10,
  })
  const [searchProduct, setSearchProduct] = useState("");
  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value)
    fetcAddPromotionList()
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(productPageState.pageSize);

  // Get all jewelary product Filter
  const [jewelaryFilters, serJewelaryFilters] = useState()
  const fetchJewellaryFlters = () => {
    axios.get(`${getJewelleryProductFilter}`)
      .then((res) => {
        serJewelaryFilters(res.data.filterElements)
      })
  }
  useEffect(() => {
    fetchJewellaryFlters()
  }, [])

  console.log(jewelaryFilters)
  // Get is All product
  const [isAllProducts, setIsAllProducts] = useState(false)
  const handleIsAllProducts = (e) => {
    setSelectedProduct({
      ...selectedProduct,
      products: []
    })
    if (e.target.checked) {
      setIsAllProducts(true)
    } else {
      setIsAllProducts(false)
    }
  }

  // Get all Products list
  const fetcAddPromotionList = () => {
    axios.defaults.headers.common["Authorization"] = null;

    let apiUrl;
    if (productType == 1) {
      apiUrl = `${getProductUser}?page=${productPageState.page + 1}&limit=${productPageState.pageSize}`;
    } else if (productType == 2) {
      apiUrl = `${getJewelleryProductUser}?category=2,1&page=${productPageState.page + 1}&limit=${productPageState.pageSize}`;
    }
    if (searchProduct) apiUrl += `&search=${searchProduct}`;
    if (filterPro.productOnsale) apiUrl += `&productOnsale=${filterPro.productOnsale}`;
    if (filterPro.productBrand) apiUrl += `&productBrand=${filterPro.productBrand}`;
    if (filterPro.productCategory) apiUrl += `&productCategory=${filterPro.productCategory}`;
    if (filterPro.orderBy) apiUrl += `&orderBy=${filterPro.orderBy}`;
    if (filterPro.productGender) apiUrl += `&productGender=${filterPro.productGender}`;
    if (filterPro.productType) apiUrl += `&jewelleryProductType=${filterPro.productType}`;
    if (filterPro.productMaterial) apiUrl += `&productMaterial=${filterPro.productMaterial}`;
    if (filterPro.productColor) apiUrl += `&productColor=${filterPro.productColor}`;

    axios.get(`${apiUrl}`)
      .then((res) => {
        const json = res.data.result
        setproductPageState(old => ({ ...old, total: json.totalDocs }))

        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1
          ele.srno = (res.data.result.page * productPageState.pageSize) + (i + 1) - productPageState.pageSize
          ele.featuredProductImage = `https://${ele.featuredProductImage}`;
          const enable = ele.flag;

          if (ele.productBrandData) {
            ele.brandName = ele.productBrandData[0]?.brandName;
          }

          if (ele.productCategory === null) {
            ele.productCategory = "";
          } else if (ele.categoryData) {
            ele.productCategory = ele.categoryData[0]?.categoryName
          }

        })
        setRowss(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetcAddPromotionList();
  }, [productPageState.page, productPageState.pageSize, searchProduct, filterPro, productType]);

  const columnss = [
    { field: 'srno', headerName: 'Sr No', width: 100 },
    { field: 'featuredProductImage', headerName: 'Image', width: 120, editable: true, renderCell: (params) => <img src={params.value} width="40px" />, },
    { field: 'productName', headerName: 'Product', width: 150 },
    { field: 'productPrice', headerName: 'Price', width: 150 },
    { field: 'brandName', headerName: 'Brand', width: 150 },
    { field: 'productCategory', headerName: 'Category', width: 150 }
  ];

  const [selectedPromo, setSelectedPromo] = useState("");


  // Delete promotion
  function MatDelete({ index }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [deletePromo, setDeletePromo] = useState([])
    const handleDeleteClick = (e) => {
      handleOpen()
      let selected = rows.find((ele) => {
        return ele._id === index;
      })
      setDeletePromo(selected)
    };
    const handleDeletePromo = (e) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.delete(GetPromotionList + "/" + deletePromo._id)
        .then((res) => {
          fetcPromotionList()
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
      handleClose();
    }

    return <FormControlLabel
      control={<>
        <div className='ActionButtons'>
          <IconButton color="secondary" aria-label="add an alarm" onClick={handleDeleteClick} className='dyFlextIcon'>
            <DeleteOutlinedIcon />
          </IconButton>
          <div className="delete-popup">
            <Button id="delete-data" onClick={handleOpen}></Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="delete-data-model" sx={style}>
                <Typography className="model-icon" id="modal-modal-title" variant="h6" component="h1">
                  <DeleteSweepIcon />
                </Typography>
                <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }}>
                  <h2>Are You Sure! Want to Delete <strong>{deletePromo.promotitle} </strong> Record?</h2>
                  <p>Do you really want to delete these records? You can't view this in your list anymore if you delete!</p>
                </Typography>
                <Typography className="model-button">
                  <Button variant="contained" className='primaryBtn order_active' onClick={handleClose} >No, Keep it</Button>
                  <Button variant="contained" className='primaryBtn order_active green' onClick={handleDeletePromo} >Yes, Delete it</Button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      </>} />;
  }



  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      // setpromofeaturedImage(e.target.files[0])
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const promoImageChange = new FormData();
    promoImageChange.append('Image', e.target.files[0]);
    axios.post(getProduct + "/productImage", promoImageChange)
      .then((res) => {
        setEditPromo({
          ...editPromo,
          promoImage: res.data.urlArray[0],
        })
        setpromofeaturedImage(res.data.urlArray[0])
      }).catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  };

  const columns = [
    { field: 'srno', headerName: 'Promo ID', width: 300 },
    { field: 'promotitle', headerName: 'Title', width: 300 },
    { field: 'createdAt', headerName: 'Date', width: 300 },
    {
      field: 'enable', headerName: 'Enable', width: 300,

      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatSwitch index={prevents.row._id} id={prevents.row.id} />
          </div>
        );
      }

    },
    {
      field: "actions", headerName: "Actions", sortable: false, width: 150,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatEdit index={prevents.row._id} />
            <MatDelete index={prevents.row._id} />
          </div>
        );
      }
    }
  ];

  // Enable / Disable Promotion
  let MatSwitch = ({ index, id }) => {
    const handleDisableCustomer = (e) => {
      let selectCustomer = rows.find((ele) => {
        return ele._id === index;
      })

      if (selectCustomer.status == 1) {
        selectCustomer.status = 2;
      } else {
        selectCustomer.status = 1;
      }

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const disableCustomer = new FormData();
      disableCustomer.append('status', selectCustomer.status);
      axios.put(GetPromotionList + "/enableDisablePromo/" + selectCustomer._id, disableCustomer)
        .then((res) => {
          fetcPromotionList();
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          ;
          toast.error(err.response.data.message, toastStyle);
        })
    }
    return <FormControlLabel
      control={
        <>
          <input type="checkbox" hidden="hidden" onChange={(e) => handleDisableCustomer(e)} id={"user" + index} checked={rows[id - 1]?.status == 1 ? true : false} />
          <label className="switch" for={"user" + index}></label>
        </>
      }
    />
  };

  // Get all promotion List
  const [rows, setRows] = useState([])
  const [pageState, setPageState] = useState({
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value)
    fetcPromotionList()
  }
  const [filter, setFilter] = useState({
    dateStart: "",
    dateEnd: ""
  })
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  useEffect(() => {
    let sday = new Date(state[0].startDate).getDate();
    let smonth = new Date(state[0].startDate).getMonth() + 1;
    let syear = new Date(state[0].startDate).getFullYear();
    if (sday < 10) sday = "0" + sday;
    if (smonth < 10) smonth = "0" + smonth;
    const startDate = `${smonth}/${sday}/${syear}`;
    setDateStart(startDate)

    let eday = new Date(state[0].endDate).getDate();
    let emonth = new Date(state[0].endDate).getMonth() + 1;
    let eyear = new Date(state[0].endDate).getFullYear();
    if (eday < 10) eday = "0" + eday;
    if (emonth < 10) emonth = "0" + emonth;
    const endDate = `${emonth}/${eday}/${eyear}`;
    setDateEnd(endDate)
  }, [state])
  const [isChanged, setIsChanged] = useState(false)
  const handleDateState = (e) => {
    setIsChanged(true)
    setState([e.selection])
  }
  const applyFilter = () => {
    if (isChanged) {
      setFilter({
        ...filter,
        dateStart: dateStart,
        dateEnd: dateEnd
      })
    }
  }
  const resetFilter = () => {
    setFilter({
      ...filter,
      dateStart: "",
      dateEnd: ""
    })
    setState([
      {
        startDate: startOfDay(new Date()),
        endDate: addDays(startOfDay(new Date()), 0),
        key: 'selection'
      }
    ]);
  }
  const fetcPromotionList = (e) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    axios.get(`${GetPromotionList}?page=${pageState.page}&limit=${pageState.pageSize}&search=${search}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
      .then((res) => {
        const json = res.data.result
        setPageState(old => ({ ...old, total: json.totalDocs }))

        res.data.result.docs.map((ele, i) => {
          ele.id = (i + 1)
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10

          let day = new Date(ele.createdAt).getDate();
          let month = new Date(ele.createdAt).getMonth() + 1;
          let year = new Date(ele.createdAt).getFullYear();
          ele.createdAt = `${day}/${month}/${year}`;

          const enable = ele.flag;

          if (ele.enable == 1) {
            ele.enable = "Order Placed"
          }
          else if (ele.enable == 2) {
            ele.enable = "Item Shipped"
          }

          else {
            ele.enable = "Promotion Dose Not Exist"
          }

        })
        setRows(res.data.result.docs)
        setIsLoading(false);
      })
  }
  useEffect(() => {
    fetcPromotionList();
  }, [pageState.page, pageState.pageSize, search, filter]);

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }


  const handleChangeEdit = (e) => {
    setEditPromo({
      ...editPromo,
      [e.target.name]: e.target.value,
    })
  }

  const [editProducts, setEditProducts] = useState([]);

  // Select Products
  const [selectedProduct, setSelectedProduct] = useState({
    products: [],
  });

  console.log(selectedProduct)
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    const { products } = selectedProduct;

    if (editProducts.includes(value)) {
      const data = editProducts.filter((res) => (res !== value))
      setEditProducts(data)
      setEditPromo({
        ...editPromo,
        selected_products: data,
      })
    }
    else {
      const data = [...editProducts]
      data.push(value)
      setEditProducts(data)
      setEditPromo({
        ...editPromo,
        selected_products: data,
      })
    }


    if (checked) {
      setSelectedProduct({
        products: [...products, value],
      })
    } else {
      setSelectedProduct({
        products: products.filter((e) => e !== value)
      })
    }
  };
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setSelectedProduct({
      products: []
    })
  };
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  return (
    <>
      <div className='innerpage-top data-collection-page pagination'>
        <div className='product-wrapper'>
          <h1>Promotions</h1>
          <Button variant="contained" className='addproduct_btn' onClick={handleOpen} >
            Add New Promo
          </Button>
          {/* ADDD PROMO */}
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <Box className="Product_Model_Container" sx={secondmodel}>
              <div className="ProductHeader">
                <div className="ProductTitel">
                  <h4>Add New Promo</h4>
                  <p>Add New Promo and necessary information from here</p>
                </div>
                <div className="ProductBtn">
                  <CloseIcon className='CloseIcon' onClick={handleClose} />
                </div>
              </div>
              <Form>

                <div className="ProductBody">
                  <Form.Group className="form-control-product" controlId="formBasicImage">
                    <Form.Label>Promo Image</Form.Label>
                    {/* <Form.Control type="file" placeholder="" />     */}
                    <div className='ChangePOrfileCard'   >
                      <input type="file" accept="image/*" multiple onChange={handleImageUpload} ref={imageUploader} style={{ display: "none" }} />
                      <div className='UserProfileCard' onClick={() => imageUploader?.current.click()} >
                        <img ref={uploadedImage} className='UserProfileImg' src={Avtar} name='promoImage' />
                        <p className='ProfileUploadImgTitle'><ImageSearchOutlinedIcon /><span>Upload Image</span></p>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group className="form-control-product" controlId="formBasicPassword">
                    <Form.Label>Promo For</Form.Label>
                    <div className="allStetus">
                      <div className='status-item '>
                        <input type="radio" name='productTypeAdd' value={1} checked={productType == 1 ? true : false} id='productTypeAdd' onChange={(e) => handleProductType(e)} />
                        <label htmlFor="productTypeAdd"><GiRunningShoe />Shoes</label>
                      </div>
                      <div className='status-item '>
                        <input type="radio" name='productTypeAdd' value={2} checked={productType == 2 ? true : false} id='productTypeAdd2' onChange={(e) => handleProductType(e)} />
                        <label htmlFor="productTypeAdd2"><GiJewelCrown />Jewellery</label>
                      </div>
                    </div>
                  </Form.Group>

                  {
                    productType == 2 ?
                      <Form.Group className="form-control-product" controlId="formBasicPassword">
                        <Form.Label>For All Products</Form.Label>
                        <div className="allStetus">
                          <input type="checkbox" onChange={(e) => handleIsAllProducts(e)} checked={isAllProducts} hidden="hidden" id="isAppProducts" />
                          <label className="switch" for="isAppProducts"></label>
                        </div>
                      </Form.Group> : ""
                  }

                  <Form.Group className="form-control-product" controlId="formBasicPassword">
                    <Form.Label>Promo Name</Form.Label>
                    <Form.Control type="text" placeholder="Promo Name" name="promotitle" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                  <Form.Group className="form-control-product" controlId="formBasicPassword">
                    <Form.Label>Notification Body</Form.Label>
                    <Form.Control type="text" placeholder="Notification Body" name="notificationBody" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                  {
                    !isAllProducts ?
                      <Form.Group className="form-control-product full-width" controlId="formBasicTitel">
                        <div className='search-and-cta'>
                          <Form.Label>Product Title/Name</Form.Label>
                          <div className='productserchBtn pageFilterBar'>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                              {(popupState) => (
                                <React.Fragment>
                                  <Button variant="contained" className='fillter_btn' {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                                    Filter
                                  </Button>
                                  <Menu {...bindMenu(popupState)}>
                                    <Scrollbar style={{ width: 500, height: 500 }}>
                                      <div className="section_p_20 product-filter">
                                        <div className="drwdownMenus">
                                          <Button className='rst-Btn-Lnk' onClick={(e) => handleResetFilter(e)} autoFocus  >
                                            Reset
                                          </Button>
                                          <Button className='aply-Btn-Lnk apply-btn' onClick={(e) => handleFilter(e)} autoFocus>
                                            Apply
                                          </Button>
                                        </div>
                                        <div className='filter-item on-sale'>
                                          <div className="drwTitle">
                                            <h2>On Sale</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <input type="checkbox" onChange={(e) => handleOnSaleFilter(e)} checked={filterOnSale == 2 ? true : false} hidden="hidden" id="onsale" />
                                            <label className="switch" for="onsale"></label>
                                          </div>
                                        </div>
                                        <div className='filter-item'>
                                          <div className="drwTitle">
                                            <h2>Price</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <div className="allStetus">
                                              <div className='status-item '>
                                                <input type="radio" name='orderBy' value={1} checked={filterOrderBy == 1 ? true : false} id='order-place' onChange={(e) => handleOrderBy(e)} />
                                                <label htmlFor="order-place">Low to High</label>
                                              </div>
                                              <div className='status-item '>
                                                <input type="radio" name='orderBy' value={2} checked={filterOrderBy == 2 ? true : false} id='shipped' onChange={(e) => handleOrderBy(e)} />
                                                <label htmlFor="shipped">High to Low</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='filter-item roles-dropdown'>
                                          <div className="drwTitle">
                                            <h2>Type</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                              <Form.Check type="checkbox" value={1} checked={filterGender?.includes(1) ? true : false} onChange={(e) => handleGenderFilter(e)} id="men" name="men" label="Men" />
                                              <Form.Check type="checkbox" value={2} checked={filterGender?.includes(2) ? true : false} onChange={(e) => handleGenderFilter(e)} id="women" name="women" label="Women" />
                                              <Form.Check type="checkbox" value={3} checked={filterGender?.includes(3) ? true : false} onChange={(e) => handleGenderFilter(e)} id="kids" name="kids" label="Kids" />
                                            </Form.Group>
                                          </div>
                                        </div>
                                        {
                                          productType == 1 ?
                                            <>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Brand</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.brands.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} value={ele._id} name={ele.brandName} onChange={(e) => handleBrandFilter(e)} checked={filterBrand.includes(ele._id) ? true : false} label={ele.brandName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Category</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.category.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} name={ele.categoryName} value={ele._id} onChange={(e) => handleCategoryFilter(e)} checked={filterCategory.includes(ele._id) ? true : false} label={ele.categoryName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Color</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.color.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} name={ele.colorName} value={ele._id} onChange={(e) => handleColorFilter(e)} checked={filterColor.includes(ele._id) ? true : false} label={ele.colorName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </> :
                                            productType == 2 ?
                                              <>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Type</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.type.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.typeName} value={ele._id} onChange={(e) => handleTypeFilter(e)} checked={filterType.includes(ele._id) ? true : false} label={ele.typeName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Material</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.material.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.materialName} value={ele._id} onChange={(e) => handleMaterialFilter(e)} checked={filterMaterial.includes(ele._id) ? true : false} label={ele.materialName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Color</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.color.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.colorName} value={ele._id} onChange={(e) => handleColorFilter(e)} checked={filterColor.includes(ele._id) ? true : false} label={ele.colorName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                              </> : ""
                                        }
                                      </div>
                                    </Scrollbar>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                            <div className='searchbaar'>
                              <div className='serach_icon'>
                                <SearchIcon />
                              </div>
                              <InputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => handleSearchProduct(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <Paper className='w-100' sx={{ width: '100%', overflow: 'hidden' }}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell><p>Selected products</p></TableCell>
                                  <TableCell align="center"><p>Sr No.</p></TableCell>
                                  <TableCell align="center"><p>Imgs</p></TableCell>
                                  <TableCell align="center"><p>Product</p></TableCell>
                                  <TableCell align="center" style={{ width: "150px" }}><p>Price</p></TableCell>
                                  <TableCell align="center"><p>Brand</p></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowss
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell align="center"><input type="checkbox" value={row._id} checked={selectedProduct.products.includes(row._id) ? true : false} onChange={(e) => handleChecked(e)} /></TableCell>
                                      <TableCell align="center">{row.srno}</TableCell>
                                      <TableCell align="center"><img src={row.featuredProductImage} alt="" /></TableCell>
                                      <TableCell align="center">{row.productName}</TableCell>
                                      <TableCell align="center">ETB {row.productPrice}</TableCell>
                                      <TableCell align="center">{row.brandName}</TableCell>
                                      {/* <TableCell align="center">{row.productCategory}</TableCell> */}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <TablePagination
                            component="div"
                            count={productPageState.total}
                            page={productPageState.page}
                            pageSize={productPageState.pageSize}
                            onPageChange={(e, newPage) => setproductPageState({ ...productPageState, page: newPage })}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={false}
                          />
                        </Paper>
                      </Form.Group> : ""
                  }
                </div>

                <div className="ProductFooter">
                  <Button className='' type='reset' autoFocus  >
                    Cancel
                  </Button>
                  <Button className='' type='submit' autoFocus onClick={(e) => handleAddPromotion(e)}>
                    Add Promotion
                  </Button>
                </div>
              </Form>
            </Box>
          </Modal>

          {/* EDIT PROMO */}
          <Modal open={openEdit} onClose={handleEditClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <Box className="Product_Model_Container" sx={secondmodel}>
              <div className="ProductHeader">
                <div className="ProductTitel">
                  <h4>Edit Promotion</h4>
                  <p>Add New Promo and necessary information from here</p>
                </div>
                <div className="ProductBtn">
                  <CloseIcon className='CloseIcon' onClick={handleEditClose} />
                </div>
              </div>
              <Form>

                <div className="ProductBody">
                  <Form.Group className="form-control-product" controlId="formBasicImage">
                    <Form.Label>Promo Image</Form.Label>
                    {/* <Form.Control type="file" placeholder="" />     */}
                    <div className='ChangePOrfileCard'   >
                      <input type="file" accept="image/*" multiple onChange={(e) => handleImageUpload(e)} ref={imageUploader} style={{ display: "none" }} />
                      <div className='UserProfileCard' onClick={() => imageUploader.current.click()} >
                        <img ref={uploadedImage} className='UserProfileImg' src={`https://${editPromo.promoImage}`} name='promoImage' />
                        <p className='ProfileUploadImgTitle'><ImageSearchOutlinedIcon /><span>Upload Image</span></p>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group className="form-control-product" controlId="formBasicPassword">
                    <Form.Label>Promo For</Form.Label>
                    <div className="allStetus">
                      <div className='status-item '>
                        <input type="radio" name='productTypeEdit' disabled value={1} checked={productType == 1 ? true : false} id='productTypeEdit' onChange={(e) => handleProductType(e)} />
                        <label htmlFor="productTypeEdit"><GiRunningShoe />Shoes</label>
                      </div>
                      <div className='status-item '>
                        <input type="radio" name='productTypeEdit' disabled value={2} checked={productType == 2 ? true : false} id='productTypeEdit2' onChange={(e) => handleProductType(e)} />
                        <label htmlFor="productTypeEdit2"><GiJewelCrown />Jewellery</label>
                      </div>
                    </div>
                  </Form.Group>

                  {
                    productType == 2 ?
                      <Form.Group className="form-control-product" controlId="formBasicPassword">
                        <Form.Label>For All Products</Form.Label>
                        <div className="allStetus">
                          <input type="checkbox" disabled onChange={(e) => handleIsAllProducts(e)} checked={isAllProducts} hidden="hidden" id="isAppProducts" />
                          <label className="switch" for="isAppProducts"></label>
                        </div>
                      </Form.Group> : ""
                  }

                  <Form.Group className="form-control-product" controlId="formBasicPassword">
                    <Form.Label>Promo Name</Form.Label>
                    <Form.Control type="text" placeholder="Promo Name" value={editPromo.promotitle} name="promotitle" onChange={(e) => handleChangeEdit(e)} />
                  </Form.Group>
                  <Form.Group className="form-control-product" controlId="formBasicPassword" disabled>
                    <Form.Label>Notification Body</Form.Label>
                    <Form.Control type="text" placeholder="Notification Body" value={editPromo.notificationBody} name="notificationBody" onChange={(e) => handleChangeEdit(e)} disabled />
                  </Form.Group>

                  {
                    !isAllProducts ?
                      <Form.Group className="form-control-product full-width" controlId="formBasicTitel">
                        <div className='search-and-cta'>
                          <Form.Label>Product Title/Name</Form.Label>
                          <div className='productserchBtn pageFilterBar'>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                              {(popupState) => (
                                <React.Fragment>
                                  <Button variant="contained" className='fillter_btn' {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                                    Filter
                                  </Button>
                                  <Menu {...bindMenu(popupState)}>
                                    <Scrollbar style={{ width: 500, height: 500 }}>
                                      <div className="section_p_20 product-filter">
                                        <div className="drwdownMenus">
                                          <Button className='rst-Btn-Lnk' onClick={(e) => handleResetFilter(e)} autoFocus  >
                                            Reset
                                          </Button>
                                          <Button className='aply-Btn-Lnk apply-btn' onClick={(e) => handleFilter(e)} autoFocus>
                                            Apply
                                          </Button>
                                        </div>
                                        <div className='filter-item on-sale'>
                                          <div className="drwTitle">
                                            <h2>On Sale</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <input type="checkbox" onChange={(e) => handleOnSaleFilter(e)} checked={filterOnSale == 2 ? true : false} hidden="hidden" id="onsale" />
                                            <label className="switch" for="onsale"></label>
                                          </div>
                                        </div>
                                        <div className='filter-item'>
                                          <div className="drwTitle">
                                            <h2>Price</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <div className="allStetus">
                                              <div className='status-item '>
                                                <input type="radio" name='orderBy' value={1} checked={filterOrderBy == 1 ? true : false} id='order-place' onChange={(e) => handleOrderBy(e)} />
                                                <label htmlFor="order-place">Low to High</label>
                                              </div>
                                              <div className='status-item '>
                                                <input type="radio" name='orderBy' value={2} checked={filterOrderBy == 2 ? true : false} id='shipped' onChange={(e) => handleOrderBy(e)} />
                                                <label htmlFor="shipped">High to Low</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='filter-item roles-dropdown'>
                                          <div className="drwTitle">
                                            <h2>Gender</h2>
                                          </div>
                                          <div className="checkstatus">
                                            <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                              <Form.Check type="checkbox" value={1} checked={filterGender?.includes(1) ? true : false} onChange={(e) => handleGenderFilter(e)} id="men" name="men" label="Men" />
                                              <Form.Check type="checkbox" value={2} checked={filterGender?.includes(2) ? true : false} onChange={(e) => handleGenderFilter(e)} id="women" name="women" label="Women" />
                                              <Form.Check type="checkbox" value={3} checked={filterGender?.includes(3) ? true : false} onChange={(e) => handleGenderFilter(e)} id="kids" name="kids" label="Kids" />
                                            </Form.Group>
                                          </div>
                                        </div>
                                        {
                                          productType == 1 ?
                                            <>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Brand</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.brands.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} value={ele._id} name={ele.brandName} onChange={(e) => handleBrandFilter(e)} checked={filterBrand.includes(ele._id) ? true : false} label={ele.brandName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Category</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.category.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} name={ele.categoryName} value={ele._id} onChange={(e) => handleCategoryFilter(e)} checked={filterCategory.includes(ele._id) ? true : false} label={ele.categoryName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                              <div className='filter-item roles-dropdown'>
                                                <div className="drwTitle">
                                                  <h2>Color</h2>
                                                </div>
                                                <div className="checkstatus">
                                                  <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                    {
                                                      shoesFilter.color.map((ele) => {
                                                        return (
                                                          <Form.Check type="checkbox" id={ele._id} name={ele.colorName} value={ele._id} onChange={(e) => handleColorFilter(e)} checked={filterColor.includes(ele._id) ? true : false} label={ele.colorName} />
                                                        )
                                                      })
                                                    }
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </> :
                                            productType == 2 ?
                                              <>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Type</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.type.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.typeName} value={ele._id} onChange={(e) => handleTypeFilter(e)} checked={filterType.includes(ele._id) ? true : false} label={ele.typeName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Material</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.material.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.materialName} value={ele._id} onChange={(e) => handleMaterialFilter(e)} checked={filterMaterial.includes(ele._id) ? true : false} label={ele.materialName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                                <div className='filter-item roles-dropdown'>
                                                  <div className="drwTitle">
                                                    <h2>Color</h2>
                                                  </div>
                                                  <div className="checkstatus">
                                                    <Form.Group className="roles-row" controlId="formBasicCheckbox">
                                                      {
                                                        jewelaryFilters.color.map((ele) => {
                                                          return (
                                                            <Form.Check type="checkbox" id={ele._id} name={ele.colorName} value={ele._id} onChange={(e) => handleColorFilter(e)} checked={filterColor.includes(ele._id) ? true : false} label={ele.colorName} />
                                                          )
                                                        })
                                                      }
                                                    </Form.Group>
                                                  </div>
                                                </div>
                                              </> : ""
                                        }
                                      </div>
                                    </Scrollbar>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                            <div className='searchbaar'>
                              <div className='serach_icon'>
                                <SearchIcon />
                              </div>
                              <InputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => handleSearchProduct(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <Paper className='w-100' sx={{ width: '100%', overflow: 'hidden' }}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell><p>Selected products</p></TableCell>
                                  <TableCell align="center"><p>Sr No.</p></TableCell>
                                  <TableCell align="center"><p>Imgs</p></TableCell>
                                  <TableCell align="center"><p>Product</p></TableCell>
                                  <TableCell align="center" style={{ width: "150px" }}><p>Price</p></TableCell>
                                  {
                                    productType == 1 ? <TableCell align="center"><p>Brand</p></TableCell> : ""
                                  }
                                  {/* <TableCell align="center"><p>Cetegory</p></TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowss
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell align="center"><input type="checkbox" value={row._id} name="" id="" onChange={(e) => handleChecked(e)} checked={Array.from(editProducts).includes(row._id) ? true : false} /></TableCell>
                                      <TableCell align="center">{row.srno}</TableCell>
                                      <TableCell align="center"><img src={row.featuredProductImage} alt="" /></TableCell>
                                      <TableCell align="center">{row.productName}</TableCell>
                                      <TableCell align="center">ETB {row.productPrice}</TableCell>
                                      {
                                        productType == 1 ? <TableCell align="center">{row.brandName}</TableCell> : ""
                                      }
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <TablePagination
                            component="div"
                            count={productPageState.total}
                            page={productPageState.page}
                            pageSize={productPageState.pageSize}
                            onPageChange={(e, newPage) => setproductPageState({ ...productPageState, page: newPage })}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[1, 3, 5]}
                          />
                        </Paper>
                      </Form.Group> : ""
                  }
                </div>

                <div className="ProductFooter">
                  <Button className='' type='reset' autoFocus onClick={handleEditClose}  >
                    Cancel
                  </Button>
                  <Button className='' type='submit' autoFocus onClick={(e) => handleEditPromo(e)}>
                    Edit Promotion
                  </Button>
                </div>
              </Form>
            </Box>
          </Modal>
        </div>
        <div className="active_btns pageFilterBar">
          <div className='explore_btns'>
            <div className='fillter'>
              <PopupState variant="popover" popupId="demo-popup-menu data-collection-filter">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="contained" className='fillter_btn' {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                      Filter
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <div className="section_p_20 data-collection-page pagination">
                        <div className="drwdownMenus">
                          <Button className='rst-Btn-Lnk' onClick={resetFilter} autoFocus  >
                            Reset
                          </Button>
                          <Button className='aply-Btn-Lnk' onClick={applyFilter} autoFocus>
                            Apply
                          </Button>
                        </div>
                      </div>
                      <div className='HL'></div>
                      <div className="datePiker">
                        {/* <label htmlFor="">Date</label> */}
                        <div className="date-range">
                          <div >
                            <DateRangePicker
                              onChange={(e) => handleDateState(e)}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={state}
                              direction="horizontal"
                            />
                          </div>
                        </div>
                      </div>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
          </div>
          <div className='searchbaar'>
            <div className='serach_icon'>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>
      <div className="data-collection-page pagination" style={{ height: 660, width: '100%' }}>
        <DataGrid
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="200" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="150" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            )
          }}
          rows={rows}
          columns={columns}
          rowCount={pageState.total}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPageState(old => ({ ...old, page: newPage + 1 }))
          }}
          onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
        // checkboxSelection

        />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Promotions