import React, { useState, useEffect } from "react";
import './DataCollection.css'
import Button from '@mui/material/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PopupState from 'material-ui-popup-state';
import { AiOutlinePlus } from 'react-icons/ai';
import "react-datepicker/dist/react-datepicker.css";
import Menu from '@mui/material/Menu';
// import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { getDataCollection } from "../../Api";
import axios from "axios";
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'
import { addDays , startOfDay } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function DataCollection() {
  const [isLoading, setIsLoading] = useState(true);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }

  const productColumns = [
    { field: 'srno', headerName: 'Sr. no.', width: 110 },
    { field: 'productItemNo', headerName: 'Product ID', width: 150 },
    { field: 'productName', headerName: 'Product Name', width: 400 },
    { field: 'brandName', headerName: 'Product Brand', width: 150 },
    { field: 'cartCount', headerName: 'In Cart Product', width: 150 },
    { field: 'wishlisted', headerName: 'liked', width: 150 },
    { field: 'viewedCount', headerName: 'Viewed', width: 150 },
    { field: 'soldCount', headerName: 'Sold', width: 150 },
  ];
  const brandColumns = [
    { field: 'srno', headerName: 'Sr. no.', width: 110 },
    { field: 'brandName', headerName: 'Brand Name', width: 300 },
    { field: 'cartCount', headerName: 'In Cart Product', width: 150 },
    { field: 'wishlisted', headerName: 'liked', width: 150 },
    { field: 'viewedCount', headerName: 'Viewed', width: 150 },
    { field: 'soldCount', headerName: 'Sold', width: 150 },
  ];
  const [productData, setProductData] = useState({});
  const [brandData, setBrandData] = useState({});
  
  const [filter, setFilter] = useState({
    dateStart: "",
    dateEnd: ""
  })
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  useEffect(() => {
    console.log(state[0])
    let startDate = new Date(state[0].startDate).toISOString();
    // let sday = new Date(state[0].startDate).getDate();
    // let smonth = new Date(state[0].startDate).getMonth() + 1;
    // let syear = new Date(state[0].startDate).getFullYear();
    // if (sday < 10) sday = "0" + sday;
    // if (smonth < 10) smonth = "0" + smonth;
    // const startDate = `${smonth}/${sday}/${syear}`;
    setDateStart(startDate)

    let endDate = new Date(state[0].endDate).toISOString();
    // let eday = new Date(state[0].endDate).getDate();
    // let emonth = new Date(state[0].endDate).getMonth() + 1;
    // let eyear = new Date(state[0].endDate).getFullYear();
    // if (eday < 10) eday = "0" + eday;
    // if (emonth < 10) emonth = "0" + emonth;
    // const endDate = `${emonth}/${eday}/${eyear}`;
    setDateEnd(endDate)
  },[state])

  const [isChanged , setIsChanged] = useState(false)
  const handleDateState = (e) => {
    setIsChanged(true)
    setState([e.selection])
  }
  const applyFilter = () => {
    if(isChanged) {
      setFilter({
        ...filter,
        dateStart: dateStart,
        dateEnd: dateEnd
      })
    }
  }
  const resetFilter = () => {
    setFilter({
      ...filter,
      dateStart: "",
      dateEnd: ""
    })
    setState([
      {
        startDate: startOfDay(new Date()),
        endDate: addDays(startOfDay(new Date()), 0),
        key: 'selection'
      }
    ]);
  }

  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    if (e.target.value != null) {
      setSearch(e.target.value)
    } else {
      setSearch("")
    }
    fetchProductData();
    fetchBrandData();
  }

  // Get All Products Data
  const fetchProductData = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getDataCollection}/productDataCollection`)
    .then((resp) => {
      axios.get(`${getDataCollection}/productDataCollection?limit=${resp.data.result.totalDocs}&search=${search}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
      .then((res) => {        
        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.brandName = ele.brandData[0]?.brandName
          ele.productName = ele.productData[0]?.productName
          ele.productItemNo = ele.productData[0]?.productItemNo
        })
        setProductData(res.data.result.docs)
        setIsLoading(false);
      })
    })
  }
  useEffect(() => {
    fetchProductData();
  }, [search, filter]);


  // Get All Brands Data
  const fetchBrandData = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getDataCollection}/brandDataCollection`)
    .then((resp) => {
      axios.get(`${getDataCollection}/brandDataCollection?limit=${resp.data.result.totalDocs}&search=${search}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
      .then((res) => {     
        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.brandName = ele.brandData[0]?.brandName
        })
        setBrandData(res.data.result.docs)
        setIsLoading(false);
      })
    })
  }
  useEffect(() => {
    fetchBrandData();
  }, [search, filter]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className='innerpage-top data-collection-page'>
        <div className="title_text">
          <h1>Data Collection</h1>
        </div>
        <div className="active_btns pageFilterBar">
          <div className='explore_btns'>
            <div className='fillter'>
              <PopupState variant="popover" popupId="demo-popup-menu data-collection-filter">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="contained" className='fillter_btn' {...bindTrigger(popupState)} startIcon={<AiOutlinePlus />}>
                      Filter
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <div className="section_p_20 data-collection-page">
                        <div className="drwdownMenus">
                          <Button className='rst-Btn-Lnk' onClick={resetFilter} autoFocus  >
                            Reset
                          </Button>
                          <Button className='aply-Btn-Lnk' onClick={applyFilter} autoFocus>
                            Apply
                          </Button>
                        </div>
                      </div>
                      <div className='HL'></div>
                      <div className="datePiker">
                        {/* <label htmlFor="">Date</label> */}
                        <div >
                          <div >
                            <DateRangePicker
                              onChange={(e) => handleDateState(e)}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={state}
                              direction="horizontal"
                            />
                          </div>
                          {/* <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} startIcon={<IosShareIcon />} /> */}
                        </div>
                      </div>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
          </div>
          <div className='searchbaar'>
            <div className='serach_icon'>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>
      <div className="flaxdyforbtn">
        <Box sx={{ width: '100%' }}>
          <Box >
            <div className='productserchBtn pageFilterBar '>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Brands" {...a11yProps(0)} />
                <Tab label="Products" {...a11yProps(1)} />
              </Tabs>
              <div className='searchbaar'>
              </div>
            </div>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="data-collection-page" style={{ height: 660, width: '100%' }}>
              <DataGrid
                rows={brandData}
                columns={brandColumns}
                pagination={false}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <img width="200" src={!isLoading && productData.length === 0 ? noData : Loader} alt="" />
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <img width="150" src={!isLoading && productData.length === 0 ? noData : Loader} alt="" />
                    </Stack>
                  )
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="data-collection-page" style={{ height: 660, width: '100%' }} value={value} index={1}>
              <DataGrid
                rows={productData}
                columns={productColumns}
                pagination={false}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <img width="200" src={!isLoading && brandData.length === 0 ? noData : Loader} alt="" />
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <img width="150" src={!isLoading && brandData.length === 0 ? noData : Loader} alt="" />
                    </Stack>
                  )
                }}
              />
            </div>
          </TabPanel>
        </Box>
      </div>
    </>
  );
}

export default DataCollection