import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useParams, unstable_HistoryRouter } from 'react-router-dom';
import Preloader from './Images/shoes.gif'
import DashBoard from './Pages/DashBoard/DashBoard';
import Order from './Pages/OrderPage/Order'
import Promotions from './Pages/PromotionPage/Promotions';
import Roles from './Pages/RolesPage/Roles';
import Products from './Pages/ProductsPage/Products';
import Employees from './Pages/EmployeesPage/Employees';
import DataCollection from './Pages/DataCollectionPage/DataCollection';
import Customers from './Pages/CustomersPage/Customers';
import BrandsCategory from './Pages/BrandsCatergoryPage/BrandsCategory';
import Login from './Pages/Login/Login';
import ChangePassword from './Pages/ChangePassword/ChangePassword';
import Vieworder from './Pages/ViewOrder/Vieworder';
import CustomerOrder from './Pages/CustomersPage/CustomerOrder/CustomerOrder';
import Error from './Pages/Error/Error';
import ErrorPanel from './Pages/ErrorPanel/Error';
import ForgotPasswordReq from './Pages/ForgotPasswordReq/ForgotPasswordReq';
import Cms from './Pages/Cms/Cms';
import Home from './Pages/Home/Home';
import DefaultLayout from './Layout/DefaultLayout';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Jewellery from './Pages/Jewellery/Jewellery';

function App() {

  const [loading, setLoading] = useState(false);
  useEffect(
    () => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }, []
  )

  const routes = [
      {
          path: "/panel",
          key:"DASHBOARD",
          page: <DashBoard />
      },
      {
          path: "/panel/dashboard",
          key:"DASHBOARD",
          page: <DashBoard />
      },
      {
          path: "/panel/order",
          key: "ORDERS",
          page: <Order />
      },
      {
          path: "/panel/vieworder/:id",
          key: "ORDERS",
          page: <Vieworder />
      },
      {
          path: "/panel/products",
          key:"PRODUCTS",
          page: <Products />
      },
      {
          path: "/panel/jewellery",
          key:"JEWELLERY",
          page: <Jewellery />
      },
      {
          path: "/panel/customers",
          key: "USERS",
          page: <Customers />
      },
      {
          path: "/panel/customerorder/:id",
          key: "USERS",
          page: <CustomerOrder />
      },
      {
          path: "/panel/promotions",
          key: "PROMOTIONS",
          page: <Promotions />
      },
      {
          path: "/panel/datacollection",
          key: "DATACOLLECTION",
          page: <DataCollection />
      },
      {
          path: "/panel/brands",
          key: "BRANDSCATEGORY",
          page: <BrandsCategory />
      },
      {
          path: "/panel/roles",
          key: "ROLES",
          page: <Roles />
      },
      {
          path: "/panel/employees",
          key: "EMPLOYEES",
          page: <Employees />
      },
      {
          path: "/panel/forgot-password-requests",
          key: "FORGOTPASSREQUESTS",
          page: <ForgotPasswordReq />
      },
      {
          path: "/panel/cms",
          key: "CMS",
          page: <Cms />
      }
  ]

  var data = {}
  var finalroutes = []
  var permissions = []

  if (localStorage.getItem('adminData')) data = JSON.parse(localStorage.getItem('adminData'))
  if (sessionStorage.getItem('adminData')) data = JSON.parse(sessionStorage.getItem('adminData'))

  if (data.permissionData) {
    if (data.type === "SUPER") finalroutes = routes
    else {
      data.permissionData.map((value) => {
          permissions.push(value.key)
      })
      finalroutes = routes.filter((ele) => {
          return permissions.includes(ele.key)
      })
    }
  }


  return (
    <div className="App">
      {
        loading ?
          <div className="loading-image">
            <img src={Preloader} />
          </div>
          :
          <Router>
            <Routes>
              {
                finalroutes.map((route) => {
                  return(
                    <Route path={route.path} element={ <DefaultLayout page={route.page}/> } />
                  )
                })
              }
              <Route path="/panel/changepassword" element={<ChangePassword />} />
              <Route path="/panel/login" element={<Login />} /> 
              <Route path="/" element={<Home />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/panel/*" element={ <DefaultLayout page={ <ErrorPanel /> }/> } />
              <Route path="/*" element={ <Error /> } />
            </Routes>
          </Router>
      }
    </div>
  );
}
export default App;