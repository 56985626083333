import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { GetCustomerList } from "../../Api";


ChartJS.register(ArcElement, Tooltip, Legend);

function AgePieChart() {
  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem("token");
  } else if (sessionStorage.getItem("token") != null) {
    token = sessionStorage.getItem("token");
  }

  const [userData, setUserData] = useState({
    range1: 10,
    range2: 10,
    range3: 10,
  });

  function categorizeAgeGroups(customersData) {
    const ageGroups = customersData.reduce(
      (acc, curr) => {
        const age = curr.age;
        if (age <= 20) {
          acc["0-20"]++;
        } else if (age <= 40) {
          acc["21-40"]++;
        } else if (age <= 60) {
          acc["41-60"]++;
        } else {
          acc["61+"]++;
        }
        return acc;
      },
      { "0-20": 0, "21-40": 0, "41-60": 0, "61+": 0 }
    );
    return ageGroups;
  }

  const [customersData, setCustomersData] = useState([]);
  const [categorizedData, setCategorizedData] = useState({});

  const fetcCustomerList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${GetCustomerList}`).then((res) => {
      res.data.result.docs.map((ele) => {
        if(ele.age === undefined) {
          ele.age = 0
        }
      })
      setCustomersData(res.data.result.docs);
    });
  };
  console.log(customersData)
  useEffect(() => {
    fetcCustomerList();
  }, []);

  useEffect(() => {
    const categories = categorizeAgeGroups(customersData);
    setCategorizedData(categories);
  }, [customersData]);

  console.log(categorizedData);

  const data = {
    labels: Object.keys(categorizedData),
    datasets: [
      {
        label: "Count",
        data: Object.values(categorizedData),
        backgroundColor: [
          "rgba(132, 27, 231, 0.9)",
          "rgba(23, 192, 159, 0.9)",
          "rgba(243, 202, 36, 0.9)",
          "rgba(242, 55, 98, 0.9)",
        ],
        borderColor: [
          "rgba(132, 27, 231, 1)",
          "rgba(23, 192, 159, 1)",
          "rgba(243, 202, 36, 1)",
          "rgba(242, 55, 98, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
      }
    }
  };

  return <Pie data={data} options={options} width={400} height={400} />;
}

export default AgePieChart;
