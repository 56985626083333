import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { GetRecentOrderList } from "../../Api";
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'
import { useNavigate } from "react-router-dom";
import { FormControlLabel, IconButton } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';


function RecentOrder() {

  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState([]);
  const MatEdit = ({ index }) => {
    const handleEditClick = () => {
      let selected = rows.find((ele) => {
        return ele._id === index
      })
      setSelectedOrder(selected)
      navigate(`/panel/vieworder/${selected._id}`);
    }
    return <FormControlLabel
      control={
        // <Link to={"/vieworder/"+selectedOrder._id}>
        <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
          <VisibilityIcon style={{ color: '#766d6d7a' }} />
        </IconButton>
        // </Link>
      }
    />
  };

  const columns = [
    { field: 'id', headerName: 'Sr No', width: 100 },
    // { field: 'productImage', headerName: 'Product Image', width: 150, renderCell: (params) => <img src={params.value} height="40px" /> },
    { field: 'OrderDate', headerName: 'Date', width: 120 },
    { field: 'OrderId', headerName: 'Order ID', width: 180 },
    { field: 'userName', headerName: 'Full Name', width: 180 },
    { field: 'orderStatus', headerName: 'Status', width: 150 },
    {
      field: 'productId', headerName: 'Product ID', width: 200,
      renderCell: (prevent) => {
        var value = prevent.row.productId.map((data) => (
          <>
            <li key={data}>{data}</li>
          </>
        ))
        return value
      }
    },
    { field: 'shippingId', headerName: 'Shipping ID', width: 180 },
    { field: 'subTotal', headerName: 'Total Amount', type: 'number', width: 150, },
    {
      field: "actions", headerName: "Actions", sortable: false, width: 140,
      renderCell: (prevent) => {
        return (
          // <Link to={"/vieworder/"+prevent.row._id}>
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatEdit index={prevent.row._id} />
          </div>
          // </Link>
        );
      }
    }
  ];
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const fetcOrderDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(GetRecentOrderList)
      .then((res) => {
        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1
          ele.productImage = "https://" + ele.products[0]?.productImage;

          // ele.productImage = []
          ele.productId = []
          ele.products.map((data) => {
            ele.productId.push(data.productItemNo)
          })

          if(ele.userData[0]?.lastName !== undefined) {
            ele.userName = ele.userData[0]?.firstName+" "+ele.userData[0]?.lastName
          } else {
            ele.userName = ele.userData[0]?.firstName
          }
          // console.log(ele.userName)

          ele.subTotal = `ETB ${ele.subTotal}`

          if (ele.orderStatus == 1) {
            ele.orderStatus = "Order Placed"
          }
          else if (ele.orderStatus == 2) {
            ele.orderStatus = "Item Shipped"
          }
          else if (ele.orderStatus == 3) {
            ele.orderStatus = "Item Delivered"
          }
          else {
            ele.orderStatus = ""
          }
        })
        setRows(res.data.result.docs)
        setIsLoading(false);
      })
  }
  useEffect(() => {
    fetcOrderDetails();
  }, []);

  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem('token');
  } else if (sessionStorage.getItem('token') != null) {
    token = sessionStorage.getItem('token')
  }

  return (
    <>
      <div className="dataGridHeight">
        <DataGrid
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="200" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <img width="150" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
              </Stack>
            )
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          pagination
        // checkboxSelection
        />
      </div>
    </>
  )
}

export default RecentOrder