import React from 'react'
import "./PrivacyPolicy.css"
import Logo from '../../Images/NIX_logo.png'
import MokupImg from '../../Images/Nix_layers.png'
import PlayStore from '../../Images/PlayStore.png'
import AppStore from '../../Images/AppStore.png'
import Phone from '../../Images/Phone.png'
import Mail from '../../Images/Mail.png'
import Phone2 from '../../Images/call-dv.svg'
import Mail2 from '../../Images/mail-dv.png'
import Ahura from '../../Images/At-logo.png'
import { NavLink } from 'react-router-dom'

function PrivacyPolicy() {
  return (
    <div className='homePage'>
      <div className='header'>
        <div className='page-container'>
          <NavLink to="/">
            <img src={Logo} className='logo' width={"180px"} alt="" />
          </NavLink>
          <ul>
            <li>
              <a href="tel:+251930252020"><img src={Phone2} alt="" />+251930252020</a>
            </li>
            <li>
              <a href="mailto:info@nix.et"><img src={Mail2} alt="" />Info@nix.et</a>
            </li>
          </ul>
        </div>
      </div>
      <div className='page-container'>
        <div className='privacypolicy'>
          <h2>Privacy Policy</h2>
          <p>This Privacy Policy governs the manner in which NIX: Ethiopia collects, uses, maintains, and discloses information collected from users of the NIX: Ethiopia mobile application.</p>
          <h3>Information Collection and Use</h3>
          <p>We may collect personal identification information from Users in various ways, including when they browse our products, create an account, and place an order within the App. The types of personal identification information we may collect include, but are not limited to, the user's name and mobile number. We will only collect personal identification information from Users if they voluntarily submit such information to us.</p>
          <h3>Information Storage and Security</h3>
          <p>We are committed to ensuring that your information is secure. We have implemented appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorised access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored in the App.</p>
          <h3>Information Usage</h3>
          <p>We may use the collected information for the following purposes:</p>
          <p>To improve customer service: The information you provide helps us respond to your customer service requests and support needs more efficiently.</p>
          <p>To personalise user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided in the App.</p>
          <p><strong>To process transactions: </strong>We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</p>
          <p>To send important notifications: We may use the mobile number provided by Users to send them important information and updates related to their order and account.</p>
          <h3>Third-Party Services</h3>
          <p>We may use third-party service providers to help us operate our business and the App or administer activities on our behalf, such as sending out notifications or conducting user verification. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</p>
          <h3>Changes to this Privacy Policy</h3>
          <p>We reserve the right to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes and to stay informed about how we are helping to protect the personal information we collect.</p>
          <h3>Your Acceptance of these Terms</h3>
          <p>By using this App, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our App. Your continued use of the App following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
          <h3>Contacting Us</h3>
          <p>If you have any questions about this Privacy Policy or the practices of this App, please contact us at <a href="mailto:info@nix.et">Info@nix.et</a>.</p>
        </div>
        <div className='contact-details'>
          <div className="page-container">
            <ul>
              <li>
                <a href="tel:+251930252020"><img src={Phone} alt="" />+251930252020</a>
              </li>
              <li>
                <a href="mailto:info@nix.et"><img src={Mail} alt="" />Info@nix.et</a>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://ahuratechnosoft.com">Designed and Developed by <span><img src={Ahura} /></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy