import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyArzIc-RANd6JO7exRS2bj_ioz6AqwgctU",
  authDomain: "nixstore2023.firebaseapp.com",
  projectId: "nixstore2023",
  storageBucket: "nixstore2023.appspot.com",
  messagingSenderId: "342614057298",
  appId: "1:342614057298:web:134b27f549d3000bd9da8b",
  measurementId: "G-SMGHPQMRQ4"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;


