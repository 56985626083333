import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { GetCustomerList } from "../../Api";


ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart() {
  let token;
  if (localStorage.getItem("token") != null) {
    token = localStorage.getItem("token");
  } else if (sessionStorage.getItem("token") != null) {
    token = sessionStorage.getItem("token");
  }

  const [userData, setUserData] = useState({});

  const fetcCustomerList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${GetCustomerList}`).then((res) => {
      setUserData({
        ...userData,
        men: res.data.manCount,
        women: res.data.womenCount,
        other: res.data.otherCount,
      });
    });
  };
  useEffect(() => {
    fetcCustomerList();
  }, []);

  const data = {
    labels: ["Men", "Female", "Other"],
    datasets: [
      {
        label: "Count",
        data: [userData.men, userData.women, userData.other],
        backgroundColor: [
          "rgba(23, 192, 159, 0.9)",
          "rgba(132, 27, 231, 0.9)",
          "rgba(243, 202, 36, 0.9)",
        ],
        borderColor: [
          "rgba(23, 192, 159, 1)",
          "rgba(132, 27, 231, 1)",
          "rgba(243, 202, 36, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
      }
    }
  };

  return <Pie data={data} options={options} width={400} height={400} />;
}

export default PieChart;