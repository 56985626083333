import React, { useState, useEffect } from "react";
import "./ForgotPasswordReq.css"
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { FormControlLabel, IconButton } from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Form from 'react-bootstrap/Form';
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { GetforgotPassReqList, getForgotPasswordRequests } from "../../Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Stack } from "@mui/system";
import Loader from '../../Images/loader.gif'
import noData from '../../Images/noData.gif'
import RefreshIcon from '@mui/icons-material/Refresh';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  
  const secondmodel = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  }

function ForgotPasswordReq() {

    function MatDelete({ index , otpCheck }) {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        const [otp, setOtp] = useState("");
        
        const [forgotPassReq, setforgotPassReq] = React.useState(false);
        const handleDelete = (e) => {
            handleOpen()
            let selected = rows.find((ele) => {
                return ele._id === index;
            })
            setforgotPassReq(selected)
        }

        const handleInputChange = (e) => {
            setOtp(e.target.value)
        };

        const handleforgotPassReq = (e) => {
            const otpData = new FormData();
            otpData.append('otp', otp);

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.put(`${getForgotPasswordRequests}/${forgotPassReq._id}` , otpData)
                .then((res) => {
                    fetcforgotPassReqList()
                    toast.success(res.data.message, toastStyle);
                    fetcforgotPassReqList()
                    handleClose();
                }).catch((err) => {
                    toast.error(err.response.data.message, toastStyle);
                })
        }


        return (
            <FormControlLabel
                control={
                    <>
                        <div className={otpCheck !== undefined ? "ActionButtons disabled" : "ActionButtons"}>
                            <Tooltip title="Update OTP">
                                <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleDelete(e)} className="dyFlextIcon" disabled={otpCheck !== undefined ? true : false }>
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box className="delete-data-model otp-model" sx={secondmodel}>
                                    <Typography className="model-icon" id="modal-modal-title" variant="h6" component="h1" >
                                        <AddToHomeScreenIcon />
                                    </Typography>
                                    <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                                        <h2> Enter One Time Password for {" "} <strong>{forgotPassReq.userName}</strong></h2>
                                        <Form className="otp-form">
                                            <Form.Group className="form-control" controlId="formBasicEmail">
                                                {/* <Form.Label>Enter 4 Digit OTP</Form.Label> */}
                                                <Form.Control type="number" placeholder="Enter 4 Digit OTP" name="email" onChange={(e) => handleInputChange(e)} />
                                            </Form.Group>
                                        </Form>
                                    </Typography>
                                    <Typography className="model-button">
                                        <Button variant="contained" className="primaryBtn order_active" onClick={handleClose} >
                                            Cancle
                                        </Button>
                                        <Button variant="contained" className="primaryBtn order_active green" onClick={(e) => handleforgotPassReq(e)} >
                                            Add OTP
                                        </Button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    </>
                }
            />
        );
    }

    const columns = [
        { field: "srno", headerName: "ID", width: 130 },
        { field: "userName", headerName: "Name", width: 250 },
        { field: "mobileNo", headerName: "Mobile No", width: 200 },
        { field: "requestDate", headerName: "Request Date", width: 250 },
        { field: 'status', headerName: 'Status', width: 190,
        renderCell: (prevents) => {
            return (
                <div className={`pfr-status status-${prevents.row.sentStatus}`} style={{ cursor: "pointer" }}>
                  {prevents.row.requestStatus}
                </div>
              );
            },
        },
        { field: "otp", headerName: "OTP", width: 250 },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 150,
            renderCell: (prevents) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <MatDelete index={prevents.row._id} otpCheck={prevents.row.otp} />
                    </div>
                );
            },
        },
    ];

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageState, setPageState] = useState({
        total: 0,
        page: 1,
        pageSize: 10
    })
    const [search, setSearch] = useState("");
    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value != null) {
            setSearch(e.target.value)
        } else {
            setSearch("")
        }
        fetcforgotPassReqList()
    }

    // Get All OTP Request List
    const fetcforgotPassReqList = () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getForgotPasswordRequests}?page=${pageState.page}&limit=${pageState.pageSize}&search=${search}`)
            .then((res) => {
                const json = res.data.result
                setPageState(old => ({ ...old, total: json.totalDocs }))

                res.data.result.docs.map((ele, i) => {
                    ele.id = i + 1;
                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10

                    const date = new Date(ele.createdAt);
                    const localDate = new Date(date.toLocaleString());
                    const localTime = date.toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true });

                    let day = new Date(localDate).getDate();
                    let month = new Date(localDate).getMonth() + 1;
                    let year = new Date(localDate).getFullYear();
                    ele.requestDate = `${day}/${month}/${year} , ${localTime}`;

                    if (ele?.status === 2) {
                        ele.status = 2;
                    } else {
                        ele.status = 1;
                    }

                    if (ele.sentStatus === 1) {
                        ele.requestStatus = "Active"
                    } else if (ele.sentStatus === 2) {
                        ele.requestStatus = "Resolved"
                    } else if (ele.sentStatus === 3) {
                        ele.requestStatus = "Expired"
                    }
                });
                setRows(res.data.result.docs);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        fetcforgotPassReqList();
    }, [pageState.page, pageState.pageSize, search]);

    const handleRefreshData = () => {
        fetcforgotPassReqList();
    }
    let token;
    if (localStorage.getItem("token") != null) {
        token = localStorage.getItem("token");
    } else if (sessionStorage.getItem("token") != null) {
        token = sessionStorage.getItem("token");
    }

    const OpenExport = () => {
        document
            .querySelector(
                ".MuiDataGrid-toolbarContainer.css-1j9kmqg-MuiDataGrid-toolbarContainer button "
            )
            .click();
        return false;
    };

    const [open, setOpen] = React.useState(false);


    return (
        <>
            <div className="Products">
                <div className="product-wrapper">
                    <h1>Forgot Password Requests</h1>
                </div>
                <div className="productserchBtn pageFilterBar">
                    <div className="fillter">
                    <Button variant="contained" className='addproduct_btn product-btn' onClick={handleRefreshData} ><RefreshIcon/>&nbsp;&nbsp;Refresh </Button>
                    </div>
                    <div className="searchbaar">
                        <div className="serach_icon">
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
            </div>
            <div style={{ height: 660, width: "100%" }}>
                <DataGrid
                    components={{
                        Toolbar: CustomToolbar,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <img width="200" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
                            </Stack>
                          ),
                          NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <img width="150" src={!isLoading && rows.length === 0 ? noData : Loader} alt="" />
                            </Stack>
                          )
                    }}
                    rows={rows}
                    columns={columns}
                    rowCount={pageState.total}
                    pagination
                    page={pageState.page - 1}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState(old => ({ ...old, page: newPage + 1 }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                // checkboxSelection
                />
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

export default ForgotPasswordReq